import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box'
import instance from "../../../../auth/utils/useJwt";
import { Grid, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";
import NewTrainingFolderDialog from "./NewTrainingFolderDialog";
import EditIcon from '@mui/icons-material/Edit';
export default function TrainingFolderSelection({
    value, onChange
}) {
    const [trainingFolders, setTrainingFolders] = useState([])
    const [editFolderId, setEditFolderId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const fetchTrainingFolders = () => {
        setLoading(true)
        setError(null)
        instance.get(`/admin/training/folder`).then(res => {
            setLoading(false)
            setTrainingFolders(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setError("Unable to fetch training folders")
        })
    }

    useEffect(() => {
        fetchTrainingFolders()
    }, [])

    return (
        <Box>

            <CustomInputLabel style={{ display: 'flex', justifyContent: 'space-between' }}>
                Folder <NewTrainingFolderDialog
                folder_id={editFolderId}
                onFolderCreated={() => {
                    setEditFolderId(null)
                    fetchTrainingFolders()
                }} />
            </CustomInputLabel>
            <Select size="small" fullWidth value={value} onChange={onChange} name="folder_id">
                {
                    trainingFolders.map(folder => (
                            <MenuItem key={folder.id} value={folder.id}>
                                
                                
                        <Stack direction="row" alignItems="center" sx={{ width: '100%' }} justifyContent="space-between">
                            <Typography>
                                {folder.name}
                                </Typography>
                                
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setEditFolderId(folder.id)
                                }}>
                                <EditIcon />
                            </IconButton>
                            </Stack>
                            </MenuItem>

                    ))
                }
            </Select>
        </Box>
    )
}