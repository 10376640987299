import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import './base.css'
const columns = [
  {
    field: 'case_romac_id', headerName: 'ID', width: 100,
    renderCell: (params) => (
      <Link to={`/patients/${params.value.split("-")[0]}-${parseInt(params.value.split("-")[1])}/case/${parseInt(params.value.split("-")[2])}`} style={{ textDecoration: 'none', fontWeight: 500 }}>
        {params.value}
      </Link>
    )
  },
  { field: 'full_name', headerName: 'Full Name', width: 150 },
  { field: 'dob', headerName: 'Date of birth', width: 150 },
  { field: 'status', headerName: 'Status', width: 120 },


  { field: 'acceptance', headerName: 'Acceptance' },
  { field: 'referral_date', headerName: 'Referral Date' },
  { field: 'country', headerName: 'Country', width: 140 },
  { field: 'classification', headerName: 'Condition' },
  { field: 'region', headerName: 'Region' },
  { field: 'districts', headerName: 'Districts' },
  { field: 'hospital', headerName: 'Hospital' },
  { field: 'clinicians', headerName: 'Clinicians' },

  { field: 'estimated_cost', headerName: 'Estimate of Cost', width: 100 },
  { field: 'actual_cost', headerName: 'Actual Cost', width: 100 },

  { field: 'sponsor', headerName: 'Sponsor' },

  { field: 'hosting_family', headerName: 'Hosting Family' },

  { field: "doctorLetter", headerName: "Doctor Letter" },
  { field: "hospitalLetter", headerName: "Hospital Letter" },
  { field: "immigrationDoc", headerName: "Immigration Doc" },
  { field: "letterOfAgreement", headerName: "Letter of Agreement" },
  { field: "letterOfOffer", headerName: "Letter of Offer" },




  { field: 'board_approval', headerName: 'Board Approval' },
  { field: 'passport_number', headerName: 'Passport Number' },
  { field: "visa_expire", headerName: "Visa Expire" },

  { field: 'arrival_date', headerName: 'Arrival Date' },
  { field: 'departure_date', headerName: 'Departure Date' },
  { field: "medical_prognosis", headerName: "Medical Prognosis" },
  { field: "prior_treatment", headerName: "Prior Treatment" },
  { field: "memo", headerName: "Memo", width: 400 }
];



const StyledDataGrid = styled(DataGrid)({
  // set width to 100%
  '& .MuiDataGrid-root': {
    width: '100%',
  },
  '& .MuiDataGrid-cell': {
    borderRight: '1px solid #ddd', // Add vertical borders to cells
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#eee', // Set table heading row background to #eee
  },
});

export default function DataGridTable(props) {
  const { loading, data, pagination, setPagination,
    columnVisibility, setColumnVisibility
  } = props

  
  const handlePageChange = (newPage) => {
    setPagination({ ...pagination, page: newPage });
  }
  return (
    <div style={{ width: '100%' }}>
      <StyledDataGrid
        rows={data}
        columns={columns}
        loading={loading}
        autoHeight
        width={'100%'}
        sx={{ width: '100%', minHeight: '100px' }}
        onPaginationModelChange={(e) => handlePageChange(e.page + 1)} // Add 1 since DataGrid uses 0-based index
        rowCount={pagination.total_results}
        pagination
        paginationMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              page: pagination.page,
              pageSize: pagination.pageSize
            },
          },
          // columns: {
          //   columnVisibilityModel: {
          //     // Hide columns status and traderName, the other columns will remain visible
          //     dob: false,
          //     prior_treatment: false,
          //     medical_prognosis: false,
          //     board_approval: false,
          //     passport_number: false,
          //     visa_expire: false,
          //     acceptance: false,
          //     hosting_family: false,
          //     sponsor: false,
          //     doctorLetter: false,
          //     hospitalLetter: false,
          //     immigrationDoc: false,
          //     letterOfAgreement: false,
          //     letterOfOffer: false,
          //     estimated_cost: false,
          //     actual_cost: false,
          //     memo: false
          //   },

          // },


        }}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibility(newModel)
        }
        pageSizeOptions={[15, 25]}
        slots={{ toolbar: GridToolbar }}
        density="compact"

      />
    </div>
  );
}