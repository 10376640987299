import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useDispatch } from 'react-redux';
import { fetchRoles, setRolesData } from '../../../../redux/roles';
import DeleteDialog from '../../../../components/Dialogs/DeleteDialog';
import instance from '../../../../auth/utils/useJwt';


export default function RoleActions({
    row
}) {
    const [deleteReq, setDeleteReq] = React.useState({
        open: false, id: null, deleting: false
    })
    const dispatch = useDispatch()


    const onAction = (action_type) => {
        if (!action_type) {
            setDeleteReq({ open: false, id: null, deleting: false })
            return
        }

        setDeleteReq({ ...deleteReq, deleting: true })
        instance.delete(`/admin/roles?id=${deleteReq.id}`).then(res => {
            setDeleteReq({ open: false, id: null, deleting: true })
            dispatch(fetchRoles())
        }).catch(err => {
            setDeleteReq({ open: false, id: null, deleting: true })
        })
    }




    return (
        <Box>
            <DeleteDialog open={deleteReq.open} onAction={onAction} deleting={deleteReq.deleting} />

            <Box>
                <IconButton aria-label='Edit' onClick={() => dispatch(setRolesData({
                    key: 'editRow', value: row
                }))}>
                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
                <IconButton aria-label='Actions' onClick={() => {
                    setDeleteReq({ open: true, id: row.id, deleting: false })
                }}>
                    <DeleteForeverOutlinedIcon />
                </IconButton>
            </Box>

        </Box>
    );
}

