import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import instance from '../../../../auth/utils/useJwt';
import { Box } from '@mui/material';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';

const NewContactForm = (props) => {
  const { onNewContact, handleClose } = props
  const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      isCustomer: false,
      isSupplier: true
    }
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const IsCustomer = watch("isCustomer");
  const isSupplier = watch("isSupplier");


  const onSubmit = async data => {
    // Replace with your backend API endpoint
    setLoading(true)
    setError(null)
    instance.post('/xero/contacts', data).then(res => {

      reset();
      handleClose();
      setLoading(false);
      onNewContact(res.data)


      console.log(res)
    }
    ).catch(err => {
      setLoading(false);
      console.log(err.response)
      setError(err.response?.data || "Something went wrong")
      console.log(err)
    })

  }


  return (
    <div>
      
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ mb: 1, mt: 3 }}>
              <CustomInputLabel>Contact name (required)</CustomInputLabel>
              <Controller
              name="Name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }} // Add validation rule
              render={({ field }) => (
                <TextField 
                  {...field} 
                  size="small" 
                  fullWidth 
                  margin="dense"
                  error={Boolean(errors.Name)} // Use errors object to check for error
                  helperText={errors.Name?.message} // Display the error message
                />
              )}
            />
            </Box>

            <Box sx={{ mb: 1, mt: 3 }}>
              <CustomInputLabel>Email</CustomInputLabel>
            <Controller
              name="EmailAddress"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} type="email" size="small" fullWidth margin="dense" />}
            />
            </Box>

            <Box sx={{ mb: 1, mt: 3 }}>
              <Controller
                name="isCustomer"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        disabled={isSupplier}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Is Contact"
                  />
                )}
              />
              <Controller
                name="isSupplier"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        disabled={IsCustomer}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Is Supplier"
                  />
                )}
              />
            </Box>
          </form>

          {
            error ? <Box sx={{ color: 'red', mt: 2 }}>{error}</Box> : null
          }
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid #eee', pb: 2, pt: 2, pr: 3 }}>
          <Button onClick={handleClose} variant='outlined' color='error'
            disabled={loading}
          >Cancel</Button>
          <Button
            disabled={loading}
            onClick={handleSubmit(onSubmit)} variant='contained'>Create</Button>
        </DialogActions>
    </div>
  );
};

export default NewContactForm;
