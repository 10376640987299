import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function BreadCrumbs(props) {
    const { data } = props
    const navigate = useNavigate()
    return (
        <Breadcrumbs separator={<span style={{ fontWeight: 'bold', fontSize: '22px' }}>/</span>} aria-label="breadcrumb">
    {
        data?.map((item, index) => {
            const commonStyles = { fontSize: '19px', color: 'text.primary', textDecoration: 'none', py: 0.5, fontWeight: index === 0 ? 'bold' : 'normal' }

            if (item.url) {
                return (
                    <Link 
                        key={`b-link-${index}`} 
                       // href={item.url} 
                        onClick={() => navigate(item.url)} 
                        sx={{ ...commonStyles, ":hover": { color: "#09f", textDecoration: 'underline', cursor: 'pointer' } }}
                    >
                        {item.text}
                    </Link>
                );
            } else {
                return (
                    <Typography 
                        key={`t-link-${index}`} 
                        sx={{ ...commonStyles}}
                    >
                        {item.text}
                    </Typography>
                );
            }
        })
    }
</Breadcrumbs>

    )
}