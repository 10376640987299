import { useEffect } from "react";

import { Button} from "@mui/material";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import SelectAutocomplete from "../PatientCases/form_comps/SelectAutocomplete";
import DatePicker from "../../../components/forms/DatePicker";
import { formatDate } from "../../../components/forms/utils";
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function FiltersForm(props) {
    const { filters, setFilters, loading } = props

    const onChangeInput = (input_name, input_value) => {
        setFilters({ ...filters, [input_name]: input_value })
    }


    useEffect(() => {
        if (filters.financial_year) {
            const yearParts = filters.financial_year.value.split(' / ');
            if (yearParts.length === 2) {
                const startYear = parseInt(yearParts[0]);
                const endYear = startYear + 1;

                // Format and update the 'from' and 'to' dates
                const fromDate = new Date(`${startYear}-07-01`); // July 1st of the starting year
                const toDate = new Date(`${endYear}-06-30`); // Jun 30st of the following year

                //onChangeInput('from', formatDate(fromDate));
                //onChangeInput('to', formatDate(toDate));

                setFilters({
                    ...filters,
                    from: formatDate(fromDate),
                    to: formatDate(toDate),
                });
            }
        }
    }, [filters.financial_year]);

    const YearList = () => {
        const startYear = 2004;
        const currentYear = new Date().getFullYear() + 1;
        const years = [];
      
        for (let i = startYear; i < currentYear; i++) {
          years.push({label : `${i} / ${String(i + 1).slice(2)}`, value: `${i} / ${String(i + 1).slice(2)}`});
        }
        return years.reverse()
    }

    return (
        <Box>
            <Box sx={{ mb: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <CustomInputLabel>Financial Year</CustomInputLabel>
                        <SelectAutocomplete options={
                            YearList()
                        } value={filters.financial_year} onChange={onChangeInput} name="financial_year" />
                    </Grid>
                    
                    <Grid item md={3}>
                        <CustomInputLabel>From</CustomInputLabel>
                        <DatePicker value={filters.from} onChange={(e) => onChangeInput("from", e.value)} />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>To</CustomInputLabel>
                        <DatePicker value={filters.to} onChange={(e) => onChangeInput("to", e.value)} />
                    </Grid>
                  
                </Grid>
            </Box>
            

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '10px' }}>
                <Button variant="outlined" disabled={loading} onClick={props.onClear}>Clear</Button>
                <Button variant="contained" disabled={loading} onClick={() => props.fetchData()}>{
                    loading ? 'Loading...' : 'Update'
                }</Button>
            </Box>
        </Box>
    )
}