import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import instance from '../../../auth/utils/useJwt';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import InvoiceAttachments from './InvoiceAttachments';
import { Link } from 'react-router-dom';
import DeleteAttachment from './components/DeleteAttachment';
import useSnackbar from '../../../components/Dialogs/SnackBar';

const InvoiceAttachmentUpload = ({ invoiceId, removeAttachments }) => {
    const [attachments, setAttachments] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const isMenuOpen = Boolean(anchorEl);
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const getAttachments = async () => {
        setLoading(true);
        instance.get(`/xero/invoices/${invoiceId}/attachments`)
            .then((response) => {
                setLoading(false);
                setAttachments(response.data.files);
            })
            .catch((error) => {
                setLoading(false);
                alert("Error fetching attachments.");
            });
    };

    useEffect(() => {
        if (invoiceId !== "new")
        getAttachments();
    }, [invoiceId]);

    const onDelete = (attachmentId) => {
        // Remove attachment from state
        setAttachments(attachments.filter(attachment => attachment.id !== attachmentId));
        
        showSnackbar('Attachment deleted successfully', 'success');
    };

    const appendAttachments = (attachment) => {
        setAttachments([...attachments, attachment]);
        showSnackbar('Attachment uploaded successfully', 'success');
    };

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                endIcon={loading ? <CircularProgress size={20} /> : isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                onClick={handleClick}
            >
                Attach Files ({attachments.length})
            </Button>
            <SnackbarComponent />
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
            >
                    <InvoiceAttachments onDelete={onDelete} invoiceId={invoiceId} attachments={attachments} appendAttachments={appendAttachments} removeAttachments={removeAttachments} />
                {attachments.map((attachment, index) => (
                            <MenuItem key={`attachment-${index}-${attachment.id}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Link to={attachment.google_drive_url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <ListItemText primary={attachment.filename?.slice(0, 50)} />
                                    </Link>
                                    {/* Add delete icon */}
                                    <ListItemIcon>
                                        <DeleteAttachment attachmentId={attachment.id} onDelete={onDelete} />
                                    </ListItemIcon>
                                    </MenuItem>
                
                            
                ))}
            </Menu>
        </div>
    );
};

export default InvoiceAttachmentUpload;