import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

function UserDetailSkeleton() {
    return (
        <Box p={2}>
            <Grid container spacing={2}>
                {/* Left panel skeleton */}
                <Grid item md={4} lg={3}>
                    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
                        <Skeleton variant="circular" width={80} height={80} />
                        <Skeleton width="80%" height={30} />
                        <Skeleton width="50%" height={20} />
                        <Skeleton width="90%" height={20} />
                        <Skeleton width="90%" height={20} />
                        <Skeleton width="90%" height={20} />
                    </Box>
                </Grid>

                {/* Right panel skeleton */}
                <Grid item md={8} lg={9}>
                    {/* Tabs skeleton */}
                    <Box mt={2}>
                        <Stack direction={'row'} spacing={2}>
                            {[...Array(7)].map((_, index) => (
                                <Skeleton width={90} height={60} key={index} />
                            ))}
                        </Stack>
                    </Box>
                    {/* User Details section */}
                    <Box mt={2}>
                        <Typography variant="h6">
                            <Skeleton width="30%" />
                        </Typography>
                        <Box mt={2}>
                            {[...Array(7)].map((_, index) => (
                                <Box key={index} display="flex" justifyContent="space-between" mt={1}>
                                    <Skeleton width="20%" height={20} />
                                    <Skeleton width="60%" height={20} />
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {/* Permissions section */}
                    <Box mt={4}>
                        <Typography variant="h6">
                            <Skeleton width="30%" />
                        </Typography>
                        <Box mt={2}>
                            {[...Array(4)].map((_, index) => (
                                <Box key={index} display="flex" justifyContent="space-between" mt={1}>
                                    <Skeleton width="20%" height={20} />
                                    <Skeleton width="60%" height={20} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UserDetailSkeleton;
