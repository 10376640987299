import { Button, Stack } from "@mui/material";

export default function FormButtons({
    setOpen, loading
}) {
    return (
        <Stack direction='row' spacing={2}>
                <Button disabled={loading} onClick={setOpen} variant='outlined'>Cancel</Button>
                <Button disabled={loading} type='submit' variant='contained' autoFocus>
                    Save
                </Button>
            </Stack>
    )
}