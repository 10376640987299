import { Select, FormControl, InputLabel, MenuItem, Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatients, setPatientsData } from "../../../../redux/patients";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";


export default function ViewsSelect() {
    const patients = useSelector(state => state.patients)
    const dispatch = useDispatch()
    const { queryArgs, queryArg } = patients


    // clear searchInput on load
    useEffect(() => {
        dispatch(setPatientsData({ key: 'searchInput', value: '' }))
    }, [])


    useEffect(() => {
        dispatch(fetchPatients(queryArg))
    }, [queryArg])

    return (
        <Box>
            <CustomInputLabel sx={{
                fontWeight: 700
            }}>Views</CustomInputLabel>
        <FormControl fullWidth>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={queryArg}
                sx={{ width: '150px' }}
                disabled={patients.loading}
                onChange={(e) => {
                    dispatch(setPatientsData({ key: 'queryArg', value: e.target.value }))
                }}
            >
                {
                    queryArgs.map((item, index) => {
                        return (
                            <MenuItem key={`query-arg-${index}`} value={item.value}>{item.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
        </Box>

    )
}