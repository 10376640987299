import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { fetchClinicians } from '../../../../../redux/medical/clinicians';
import CustomInputLabel from '../../../../../components/forms/CustomInputLabel';
import { useForm } from 'react-hook-form';
import instance from '../../../../../auth/utils/useJwt';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';


export default function AddClinican() {
    const [open, setOpen] = useState(false)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const dispatch = useDispatch()
    const { register, reset, handleSubmit,
        formState: { errors },
    } = useForm();


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })

        instance.post("/clinicians", data).then(res => {
            setApiReq({ loading: false, error: null })
            dispatch(fetchClinicians())
            reset({ name: "", id: null })
            setOpen(false)
        }).catch(err => {
            if (err.response && err.response.status && err.response.data) {
                setApiReq({ loading: false, error: err.response.data });
            } else {
                setApiReq({ loading: false, error: "Error adding new clincian" });
            }
        })

    }
    const onError = (err) => {
        console.log(err)
    }


    return (
        <div>
            <Typography onClick={() => setOpen(true)} color={"primary"} sx={{
                fontSize: '13px', display: 'inline', "&:hover": {
                    color: "#000", textDecoration: 'underline', cursor: 'pointer'
                }
            }}>New</Typography>
            <GeneralDialog open={open} handleClose={() => setOpen(false)} title="Add Clinician" disabled={apiReq.loading}
                onFormSubmit={handleSubmit(onSubmit, onError)}
                actions={
                    <Button disabled={apiReq.loading} type='submit' variant='contained' autoFocus>
                        {
                            apiReq.loading ? `SAVING` : `SAVE`
                        }
                    </Button>
                }
            >
                <Box>
                    <CustomInputLabel>Clinician Name</CustomInputLabel>
                    <TextField {...register('name', { required: true })} error={Boolean(errors.name)} size='small' fullWidth />

                </Box>
                {apiReq.error &&
                    <Box sx={{ mt: 1 }}>
                        <Typography color="error"> {apiReq.error}</Typography>
                    </Box>
                }
            </GeneralDialog>
        </div>
    );
}
