import { Box, CircularProgress, IconButton, Stack } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import instance from "../../../../auth/utils/useJwt";
import { useEffect, useState } from "react";
import './style.css'
import parse from 'html-react-parser';
export default function SingleTraining({
    open, setOpen, activeTrainingId, folder, folderMeta, mainLoading, nextPage, prevPage
}) {
    const [training, setTraining] = useState(null)
    const [loading, setLoading] = useState(true)

    const fetchTraining = async () => {
        setLoading(true)
        instance.get(`/training?folder=${folderMeta?.slug}&slug=${activeTrainingId}`).then(res => {
            setLoading(false)

            //assign each h2 heading an id
            const domParser = new DOMParser();
            const doc = domParser.parseFromString(res.data.content, 'text/html');
            const headings = doc.querySelectorAll('h2');
            headings.forEach((heading, index) => {
                heading.id = `heading-ehr-${index}`
            })

            res.data.content = doc.body.innerHTML

            setTraining(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTraining()
    }, [folder, activeTrainingId])



    return (
        <Box sx={{
            width: open ? "calc(100% - 300px)" : "100%",
            // Transition added for smooth animation
            transition: "width 0.5s",
            height: "100vh",
        }}>
            {!open &&
                <Box>
                    <IconButton onClick={() => setOpen(true)}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            }

            {
                loading || mainLoading ? <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress />
                </Box>

                    :
                    <Box className="single-training" sx={{
                        px: 2
                    }}>
                        <Stack direction={"column"} justifyContent={"space-between"} sx={{
                            height: "80vh"
                        }}>
                            {
                                training?.content && <Box>
                                    {parse(training.content)}
                                </Box>
                            }

                            <Box>

                                <Stack direction={"row"} justifyContent={"space-between"} sx={{ py: 1 }}>
                                    <IconButton onClick={() => prevPage()}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                    <IconButton onClick={() => nextPage()}>
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Stack>


                            </Box>

                        </Stack>
                    </Box>
            }



        </Box>
    )
}