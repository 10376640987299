import { useEffect, useState } from "react";
import { Button, Card, CardContent } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FiltersForm from "./Filters";
import DataTable from "./DataTable";

import dayjs from 'dayjs';

import instance from "../../../auth/utils/useJwt";
import DataGridTable from "./DataGridTable";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";

export default function ReportsExpenditures() {

    const [caseExpenditures, setCaseExpenditures] = useState([])
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const [exportReq, setExportReq] = useState({
        loading: false, error: null
    })
    const [filters, setFilters] = useState({
        date: null,
        from: dayjs().subtract(8, 'month').format('MM-DD-YYYY'),
        to: dayjs().format('MM-DD-YYYY'), status: null, region: null, district: null,
        condition: null, country: null, hospital: null, clinician: null, visa_expiry: false
    })

    const onClear = () => {
        setFilters({
            date: null,
            from: dayjs().subtract(8, 'month').format('MM-DD-YYYY'),
            to: dayjs().format('MM-DD-YYYY'), status: null, region: null, district: null,
            condition: null, country: null, hospital: null, clinician: null, visa_expiry: false
        })
    }

    const fetchReports = () => {
        setApiReq({ loading: true, error: null })
        instance.post("/reports/case-expenditures", filters).then(res => {
            setCaseExpenditures(res.data)
            setApiReq({ loading: false, error: null })
        }).catch(err => {

            setApiReq({ loading: false, error: "Error fetching records" })
        })
    }

    const exportData = () => {
        setExportReq({ loading: true, error: null })
        var send_f = { export: true, ...filters }
        instance.post("/reports/case-expenditures", send_f).then(res => {
            window.open(res.data, '_blank', 'noreferrer')
            setExportReq({ loading: false, error: null })
        }).catch(err => {

            setExportReq({ loading: false, error: "Error fetching records" })
        })
    }

    useEffect(() => {
        fetchReports()
    }, [])

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Expenditures" },
    ]

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Card>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Filters</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <FiltersForm filters={filters} setFilters={setFilters} fetchData={fetchReports} onClear={onClear} loading={apiReq.loading} />
                    </Box>
                </Card>

                <Box sx={{ textAlign: 'center', py: 2 }}>
                    <Typography variant="h5">Expenditure Report<br />
                        ROMAC LTD.</Typography>
                    <Typography sx={{ mt: 2 }}>For Financial Year 2022/23</Typography>
                </Box>

                <Card sx={{ mt: 1 }}>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Cases</Typography>
                        </Box>
                    </Box>
                    <Box>
                        {/* <DataTable data={caseExpenditures} /> */}
                        <DataGridTable data={caseExpenditures} loading={apiReq.loading} />
                    </Box>
                    <Box sx={{ borderTop: '1px solid #eee', p: 2 }}>
                        <Button onClick={exportData} disabled={exportReq.loading} variant="contained">
                            Export
                        </Button>
                    </Box>
                </Card>
            </Box>
        </AppLayout>
    )
}