import React, { useEffect } from 'react';
import {
    Button,
    TextField,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography, Box, Divider
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import instance from '../../../auth/utils/useJwt';
import InvoiceAttachmentUpload from './AttachmentsUpload';
import MUIAutocompleteBasic from './components/MUIAutocompleteBasic';

export default function InvoiceItemsTable(props) {
    const { LineItems, setLineItems,
        appendAttachments, removeAttachments,
        setTaxAmount
    } = props
    const [xeroItems, setXeroItems] = React.useState([])
    const [xeroAccounts, setXeroAccounts] = React.useState([])
    const [taxRates, setTaxRates] = React.useState([])
 

    const fetchData = (api_path, setSt) => {
        instance.get(api_path).then(({ data }) => {
            if (api_path === '/xero/accounts') {
                setTaxRates(data.taxrates)
                setXeroAccounts(data.accounts)
            }else{
                setSt(data)
            }
        }
        ).catch(err => {
        })
    }

    useEffect(() => {
        fetchData('/xero/items', setXeroItems)
        fetchData('/xero/accounts', setXeroAccounts)
    }, [])

    const handleChange = (e) => {
        const { name, value, index } = e
        const newLineItems = [...LineItems]
        newLineItems[index][name] = value
        console.log(name, value)
        if (name === "ItemCode") {
            // Find item and set description and unit price (if any)
            const item = xeroItems.find(item => item.code === value)
            console.log(item)
            if (item) {
                newLineItems[index].Description = item.description
                if (item.purchase_details) {
                    newLineItems[index].UnitAmount = item.purchase_details.UnitPrice
                    newLineItems[index].AccountCode = item.purchase_details.AccountCode
                    newLineItems[index].TaxType = item.purchase_details.TaxType
                    // filter account code
                    // const filtered = xeroAccounts.filter(account => account.code === item.purchase_details.AccountCode)
                    
                }

                // item account code
                // const account_code = item.salesDe

            }
        }


        setLineItems(newLineItems)
    }

    const onTextInputChange = (e, index) => {
        const { name, value } = e.target
        const newLineItems = [...LineItems]
        newLineItems[index][name] = value
        setLineItems(newLineItems)
    }

    const addNewRow = () => {
        setLineItems([...LineItems, {
            Description: "",
            Quantity: 0,
            UnitAmount: 0,
            AccountCode: "",
            TaxType: "",
            ItemCode: ""
        }])
    }

    const onRemoveRow = (index) => {
        const newLineItems = [...LineItems]
        newLineItems.splice(index, 1)
        setLineItems(newLineItems)
    }

    const calculateTotalGST = () => {
        const taxGSTMAP = taxRates.reduce((acc, item) => {
            acc[item.id] = item.rate
            return acc
        }, {})
        var total_gst = 0
        LineItems.forEach((item, index) => {
            const taxRate = taxGSTMAP[item.TaxType]
            if (taxRate) {
                total_gst += (item.UnitAmount * item.Quantity) * (taxRate / 100)
            }
        })
        setTaxAmount(total_gst)
        return total_gst
    }

    const total = LineItems.reduce((acc, item, index) => {
        return acc + (item.UnitAmount * item.Quantity)
    }, 0)
    
    
    return (
        <div style={{ marginTop: '20px' }}>
            {/* ... other form fields ... */}
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#f6f6f7' }}>
                            <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>Item

                                {/* <NewItemDialog xeroAccounts={xeroAccounts} onNewItem={(item) => {
                                    setXeroItems([...xeroItems, item])
                                }} /> */}
                            </TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell>Tax Rate</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {LineItems.map((item, index) => (
                            <TableRow key={`item-${item.LineItemID}-${index}`} sx={{ padding: 0 }}>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', minWidth: '170px', paddingLeft: 0, paddingRight: 0 }}>
                                    {xeroItems.length > 0 &&
                                        <MUIAutocompleteBasic options={
                                            // Contacts as options (convert to label and value)
                                            xeroItems.map((item) => ({
                                                label: item.name,
                                                value: item.code
                                            }))}
                                            index={index}
                                            value={item.ItemCode}
                                            onChange={handleChange}
                                            name={`ItemCode`}
                                            required
                                            border={false}
                                        />
                                    }

                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        size='small'
                                        index={index}
                                        name="Description"
                                        onChange={(e) => onTextInputChange(e, index)}
                                        value={item.Description}
                                        placeholder='Description'
                                        InputProps={{ disableUnderline: true }}
                                    />

                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        index={index}
                                        onChange={(e) => onTextInputChange(e, index)}
                                        value={item.Quantity}
                                        name="Quantity"
                                        type="number"
                                        size='small'
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TextField
                                        name="UnitAmount"
                                        onChange={(e) => onTextInputChange(e, index)}
                                        variant="standard"
                                        fullWidth
                                        index={index}
                                        onBlur={(e) => {
                                            const { name, value } = e.target
                                            const newLineItems = [...LineItems]
                                            newLineItems[index][name] = parseFloat(value).toFixed(2)
                                            setLineItems(newLineItems)
                                        }}
                                        type="number" // Change to text to manage formatting
                                        size='small'
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        value={item.UnitAmount}
                                    />

                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', paddingLeft: 0, paddingRight: 0, minWidth: '200px' }}>
                                    {xeroAccounts.length > 0 && item.AccountCode &&
                                        // <MUIAutocompleteBasic options={
                                        //     // Contacts as options (convert to label and value)
                                        //     xeroAccounts.map((account) => ({
                                        //         label: account.name,
                                        //         value: account.code
                                        //     }))}
                                        //     index={index}
                                        //     disabled
                                        //     value={item.AccountCode}
                                        //     name={`AccountCode`}
                                        //     onChange={handleChange}
                                        //     required
                                        //     border={false}
                                        // />
                                        // Just display name for selected account 
                                        
                                        <Box sx={{ px: 1 }}>
                                        {xeroAccounts.find(account => account.code === item.AccountCode)?.name}
                                        </Box>
                                        
                                    }
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', paddingLeft: 0, paddingRight: 0, minWidth: '200px' }}>
                                    {taxRates.length > 0 &&
                                        <MUIAutocompleteBasic options={
                                            // Contacts as options (convert to label and value)
                                            taxRates.map((txr) => ({
                                                label: txr.name,
                                                value: txr.id
                                            }))}
                                            name={`TaxType`}
                                            index={index}
                                            value={item.TaxType}
                                            onChange={handleChange}
                                            required
                                            border={false}
                                        />
                                    }
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TextField disabled variant="standard" fullWidth size='small'
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={
                                            item.UnitAmount ? `$${(item.UnitAmount * item.Quantity).toFixed(2)}` : "$0.0"
                                        }
                                    />
                                </TableCell>

                                <TableCell>
                                    <IconButton onClick={
                                        () => onRemoveRow(index)
                                    } size='small'>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button startIcon={<AddCircleOutlineIcon />} onClick={addNewRow}>
                Add Line Item
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    {props.invoice_id !== "new" &&
                        <InvoiceAttachmentUpload invoiceId={props.invoice_id} attachments={props.attachments}
                            appendAttachments={appendAttachments} removeAttachments={removeAttachments} />
                    }
                </Box>
                <Box sx={{ width: '300px', py: 1, my: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{ mt: 2 }}>Sub Total</Typography>
                    <Typography sx={{ mt: 2 }}>
                        ${
                            total ? total.toFixed(2) : "0.0"
                        }
                    </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
                    <Typography sx={{ mt: 1 }}>Total GST</Typography>
                    <Typography sx={{ mt: 1 }}>
                        ${
                            calculateTotalGST().toFixed(2)
                        }
                    </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{ mt: 2 }}>Total</Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        ${
                            total ?
                                <span>
                                {
                            (LineItems.reduce((acc, item, index) => {
                                return acc + (item.UnitAmount * item.Quantity)
                            }, 0) + calculateTotalGST())?.toFixed(2)
                        }
                            </span>
                        : "0.0" }
                    </Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
