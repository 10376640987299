import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import instance from "../../../../auth/utils/useJwt";
import DataRows from "./DataRows";
import LoadSkeleton from "./Skeleton";

// <MenuItem value={0}>ROTARY CLUB</MenuItem>
//                                             <MenuItem value={1}>PRIVATE INDIVIDUAL</MenuItem>
//                                             <MenuItem value={2}>MEDICAL PERSON</MenuItem>
//                                             <MenuItem value={3}>OTHER</MenuItem>
const referrer_type = {
    0: "ROTARY CLUB",
    1: "PRIVATE INDIVIDUAL",
    2: "MEDICAL PERSON",
    3: "OTHER"
}

export default function Referrer({
    patient_id
}) {
    const [ loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const get_referrer = () => {
        instance.get(`/referrer?id=${patient_id}`).then(res => {
            setData([
                { title: "Referrer Type", value: referrer_type[res.data.type] },
                { title: "Title", value: res.data.title },
                { title: "Name", value: res.data.name },
                { title: "Mobile", value: res.data.mobile },
                { title: "Phone Number", value: res.data.landline },
                { title: "Email", value: res.data.email },
                { title: "Postal Address", value: res.data.address },
                { title: "Additional Details", value: res.data.additional }
            ])
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_referrer()
    }, [])

    return (
        <Box>
            { loading ? <LoadSkeleton  rows={8} /> :
            <DataRows title={"Referrer"} data={data} />
    }
        </Box>
    )
}