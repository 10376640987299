import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { formatDate } from '../../../../components/forms/utils';
export default function InvoiceMetaTable ({
    invoice
}) {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Date Added</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">BSB/BPAY Code/IBAN</TableCell>
                        <TableCell align="right"> Account No./Reference/Swift Code</TableCell>
                        <TableCell align="right">User Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { !invoice?.created_at ?
                    <TableRow>
                        <TableCell colSpan={5} sx={{ backgroundColor: "#E5F6fD", color: "#03F", textAlign: 'center', fontSize: '12px', py: 1 }}>
                        Payment details have not yet been added for this invoice. Add Payment Details
                        </TableCell>
                    </TableRow>
                    :
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        
                        <TableCell>{`${invoice.created_at?.slice(4, 30)}`}</TableCell>
                        <TableCell component="th" scope="row">
                            {/* <Tooltip title={invoice.expense_type} placement="top"> */}
                            {invoice.expense_type}
                            {/* </Tooltip> */}
                        </TableCell>
                        <TableCell align="right">{invoice.bsb || invoice.biller_code || invoice.iban || "-"}</TableCell>
                        <TableCell align="right">{invoice.account_number || invoice.reference || invoice.swift_bic || "-"}</TableCell>
                        <TableCell align="right">{invoice.user}</TableCell>
                    </TableRow>
}
                </TableBody>
            </Table>
        </TableContainer>
    );
}