import AuthLayout from '../../../components/layout/AuthLayout'
import IconImage from '../../../assets/images/icons/romac-logo_480 1.png'
import { Box, Typography, FormControlLabel, Checkbox, Alert } from '@mui/material'
import {  useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import axios from 'axios'
import { API_URL } from '../../../configs/endpoints';
import { useEffect, useState } from 'react';


const SiginButton = styled('button')({
    backgroundColor: "#DB4437", cursor: 'pointer',
    borderRadius: '8px', padding: '13px 22px', outline: 'none',
    border: 'none', color: "#fff", width: '100%',
    "&:hover": {
        backgroundColor: '#db6056'
    },
    "&[disabled]": {
        backgroundColor: '#A5A5A5', // Changed background color for disabled state
        cursor: 'not-allowed',     // Cursor indicating the item is not interactive
        "&:hover": {
            backgroundColor: '#A5A5A5' // Make sure the hover doesn't change color either
        }
    }
});

export default function Login() {
    const location = useLocation();
    const [isCheckboxChecked, setCheckboxChecked] = useState(true);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nextValue = queryParams.get('next');

        // Store the `next` value in local storage
        if (nextValue) {
            localStorage.setItem('nextRomac', nextValue);
        }
    }, [location.search]);


    const onLoginClick = () => {
        axios.post(`${API_URL}/api/v1/auth-google`).then(res => {
            window.location.href = res.data
        }).catch(err => {
            console.log(err)
        })
    }

    const [isLoggedOut, setIsLoggedOut] = useState(false);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionExpired = queryParams.get('sessionExpired');

        if (sessionExpired === "true") {
            setIsLoggedOut(true);
        }
    }, [location.search]);



    return (
        <AuthLayout>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', flexDirection: 'column' }}>
            {
                isLoggedOut && 
                <Alert severity="error" sx={{ borderRadius: '8px', marginBottom: '10px', position: 'fixed', top: 0, left: 0, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    Your session has expired. Please log in again.
                </Alert>

            }
            <Box sx={{ padding: '15px 30px', textAlign: 'center' }}>
                <img src={IconImage} alt='ROMAC Icon' style={{ height: '70px' }} />
                <hr style={{ marginBottom: '0px' }} />

                <Box sx={{ textAlign: 'left', py: 2 }}>
                    <Typography variant='h5' sx={{ textAlign: 'center', mt: 0 }}>Welcome to ROMAC eHR</Typography>
                    <p style={{ color: "#4B465C", textAlign: 'center', marginTop: '5px' }}>Please sign into your Google Workspace account to get started</p>

                    <div
                        style={{ width: '100%', maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '5px', border: '1px solid #eee', paddingLeft: '10px' }}
                    >

                        <p style={{ fontSize: '14px', fontWeight: 600 }}> Review and accept these terms to proceed use ROMAC's online services.</p>
                        <ol style={{ fontSize: '13px', paddingLeft: '20px', paddingRight: '10px', lineHeight: '1.6em' }}>

                            <li>I understand and acknowledge the sensitive nature of the information I may access within this system, related to sick children and their medical assistance.</li>

                            <li>I will use the information and tools provided within this system exclusively for the purpose of case management and related activities under the ROMAC program.</li>

                            <li>I will maintain the confidentiality of all patient and program-related information and comply with all applicable privacy laws and regulations.</li>

                            <li>I will adhere to the ethical guidelines and best practices established by the ROMAC program and its affiliated organizations.</li>

                            <li>I will report any suspicious or unauthorized access or activity within this system promptly to the appropriate authorities.</li>

                            <li>I understand that unauthorized access, misuse, or breach of patient confidentiality may result in legal consequences.</li>
                        </ol>
                    </div>

                    {/* Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheckboxChecked}
                                onChange={e => setCheckboxChecked(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="By clicking Log In, you understand and agree to the terms above."
                        sx={{ '& .MuiTypography-root': { fontSize: '0.8rem' } }}
                    />


                </Box>

                <SiginButton disabled={!isCheckboxChecked} onClick={onLoginClick}>Sign in to ROMAC</SiginButton>
            </Box>
            <Box sx={{ backgroundColor: "#D9D9D9", py: 1, textAlign: 'center', color: "#000", fontSize: '14px', fontWeight: 500 }}>
                Version 2.0 (Nov 2023) - Development & Support by <a style={{ textDecoration: 'none' }}
                rel='noreferrer'
                target='_blank' href='https://gajupy.com'>Gajupy.com</a></Box>
            </Box>
        </AuthLayout>
    )
}