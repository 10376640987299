import React, { useState, useEffect } from 'react';
import { Box, Button, Popper, ClickAwayListener } from '@mui/material';
import {
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridFilterPanel,
    GridColumnsPanel
} from '@mui/x-data-grid';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomVisibleColumns from './toolbar/CustomColumnsPanel';

function CustomToolbar({
    columns, visibleColumns, setVisibleColumns, customHeaderSX
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeButton, setActiveButton] = useState('columns'); // Default to 'columns'
    const [popperWidth, setPopperWidth] = useState(null);

    const handleClick = (event) => {
        const buttonName = event.currentTarget.name;

        if (!anchorEl) {
            // Open the Popper and set the initial button
            setAnchorEl(event.currentTarget);
            setActiveButton(buttonName);
        } else {
            // Update the active button and keep Popper open
            setActiveButton(buttonName);
        }
    };

    useEffect(() => {
        if (anchorEl) {
            const popperWidth = anchorEl.getBoundingClientRect().width;
            setPopperWidth(popperWidth);
        } else {
            setPopperWidth(null);
        }
    }, [anchorEl]);

    const handleClickAway = () => {
        setAnchorEl(null);
        setActiveButton(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <GridToolbarContainer
                sx={{
                    px: 1,
                    position: 'relative',
                    top: customHeaderSX?.height ? -50 - customHeaderSX?.height : -50,
                    width: '350px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    right: 5,
                    height: '30px',
                    marginBottom: '-34px',
                }}
            >
                {/* <CustomVisibleColumns columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} /> */}
                <Button startIcon={<ViewWeekIcon />} onClick={handleClick} name="columns" color="primary">
                    Columns
                </Button>
                <Button startIcon={<FilterListIcon />} onClick={handleClick} name="filter" color="primary">
                    Filters
                </Button>
                <GridToolbarDensitySelector />
                <Popper id={id} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start"
                    className='MuiPopper-root MuiDataGrid-panel'
                    sx={{
                        zIndex: 9999, backgroundColor: "#fff",
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        borderRadius: '4px',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                        minWidth: '300px',
                        maxHeight: '450px',
                        display: 'flex',
                        maxWidth: 'calc(-4px + 100vw)',
                        overflow: 'auto'
                    }} modifiers={[{
                        name: 'preventOverflow',
                        options: {
                            altBoundary: true,
                            tether: false,
                        },
                    }, {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['bottom-start'],
                        },
                    }]}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ maxWidth: `calc(100vw - ${popperWidth ? popperWidth : 0}px)` }}>
                            {activeButton === 'columns' ? <GridColumnsPanel /> : <GridFilterPanel />}
                        </div>
                    </ClickAwayListener>
                </Popper>
            </GridToolbarContainer>
        </Box>
    );
}

export default CustomToolbar;
