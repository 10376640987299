import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import instance from '../../../../auth/utils/useJwt';
import DatePicker from '../forms/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { setCaseValue } from '../../../../redux/case/case';


const rows = [
    { "id": "doctorLetter", "title": "Doctors Letter" },
    { "id": "hospitalLetter", "title": "Hospital Letter" },
    { "id": "immigrationDoc", "title": "Immigration Letter" },
    { "id": "letterOfAgreement", "title": "Letter of Agreement" },
    { "id": "letterOfOffer", "title": "Letter of Offer" },
];

export default function Checklist() {
    const [files, setFiles] = useState([])
    const { data, loading } = useSelector(state => state.patient_case)
    const checklist = data?.checklist

    const dispatch = useDispatch()

    useEffect(() => {
        instance.get(`/case/files?drive_id=${data?.drive_id}&all=true`).then(res => {
            var out = []
            res.data.map(item => (
                out.push({
                    value: item.id, label: item.name, url: item.webViewLink
                })
            ))
            setFiles(out)
        }).catch(err => {
        })
    }, [data?.drive_id])

    const onChange = (item) => {
        const updatedItem = {
            ...checklist[item.name],
            [item.key]: item.value
        };

        const payload = {
            ...checklist,
            [item.name]: updatedItem
        };

        dispatch(setCaseValue({ name: "checklist", value: payload }))
    }

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
            <CircularProgress />
        </Box>
    }

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Document Type</TableCell>
                        <TableCell>Written/Received</TableCell>
                        <TableCell>Selection</TableCell>
                        <TableCell>Recipient</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{ minWidth: '150px' }}>
                                {row.title}
                            </TableCell>
                            <TableCell sx={{ minWidth: '250px' }}>
                                <DatePicker name={row.id} value={checklist[row.id].received_date} onChange={
                                    (data) => onChange({ ...data, key: 'received_date' })
                                } />
                            </TableCell>
                            <TableCell sx={{ minWidth: '250px', width: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={files}
                                    fullWidth
                                    value={checklist[row.id].file}
                                    onChange={(e, val) => onChange({
                                        name: row.id, value: val, key: "file",
                                    })}
                                    size="small"
                                    renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                />
                            </TableCell>
                            <TableCell sx={{ minWidth: '320px' }}>
                                <TextField value={checklist[row.id].recipient} onChange={(e) => onChange({
                                    name: row.id, value: e.target.value, key: 'recipient'
                                })} size='small' />
                                <Button sx={{ ml: 1 }} variant='outlined' onClick={() => window.open(checklist[row.id].file?.url, '_blank')}>View</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
