import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import instance from '../../../../auth/utils/useJwt';
import LoadSkeleton from './Skeleton';
import DataRows from './DataRows';
const relationship_map = {
  0: "MOTHER",
  1: "FATHER",
  2: "AUNT",
  3: "UNCLE",
  4: "SISTER",
  5: "BROTHER",
  6: "GRANDMOTHER",
  7: "GRANDFATHER",
  8: "GUARDIAN",
  9: "COUSIN",
  10: "FRIEND",
  11: "OTHER"
}


export default function Guardians({
    patient_id
}) {
  const [loading, setLoading] = useState(true)
  const [guardians, setGuardians] = useState([])

  const get_guardians = () => {
    setLoading(true)
    instance.get(`/guardian?id=${patient_id}`).then(res => {
      console.log(res)
      setGuardians(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  const get_languages = (langs) => {
    var langs_str = ""
    langs.forEach(element => {
        langs_str += ` ${element.name}` + ","
    });

    return langs_str.slice(0, -1);
}

  
  useEffect(() => {
    // On First load fetch all guardians
    get_guardians()
  }, [])

  return (
    <Box>
      {
        loading ?
          <Box>
            <LoadSkeleton rows={4} />
          </Box>
         :
         <Box>
                {guardians.map((row) => (

                    <DataRows
                    key={`guardian-${row.id}`}
                    title={relationship_map[row.relationship]} data={[
                        { title: "First Name", value: row.first_name },
                        { title: "Last Name", value: row.last_name },
                        { title: "Alias", value: row.alias },
                        { title: "Email", value: row.email },
                        { title: "Date of Birth known", value: row.dob ? "Yes" : "No" },
                        { title: "Date of Birth", value: row.dob },
                        { title: "Estimate the year of birth", value: row.dob_text },
                        { title: "Mobile", value: row.mobile },
                        { title: "Landline", value: row.home_phone },
                        { title: "Address", value: row.address },
                        { title: "Occupation", value: row.occupation },
                        { title: "Understands English", value: row.understands_english },
                        { title: "Passport Number", value: row.passport_number },
                        { title: "Passport Expiry", value: row.passport_expiry },
                        { title: "Religion", value: row.religion },
                        { title: "Languages Spoken", value: get_languages(row.languages) },
                    ]} />
                  
                ))}
             
            </Box>
     }
     
    </Box>
  );
}
