import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { MUICheckboxInput } from '../../../../components/forms/MUICheckboxInput';
import { CanAuthorizeExpenses } from '../../../../redux/user_roles';
import { useParams } from 'react-router-dom';


export default function AuthorizeInvoiceDialog({
    processing, setLastClicked, selectedInvoice, invoicePaymentData,
    control, errors
}) {
    const { patient_id, case_id, invoice_id } = useParams()

    const [open, setOpen] = useState(false)
    
    const canAuthorize = useSelector(CanAuthorizeExpenses)
    return (
        <>
            {
                canAuthorize &&
                <>
                    {invoicePaymentData?.created_at === null || invoicePaymentData?.created_at === undefined || invoice_id === "new" ?
                        <Tooltip title="Payment details not added" placement="top">
                            <Button
                                size='small'
                                sx={{ textTransform: 'none', backgroundColor: '#f0f0f0', boxShadow: 'none',
                                    color: '#888', cursor: 'not-allowed', border: '1px solid #ccc',
                                    "&:hover": {
                                        backgroundColor: "#f0f0f0", boxShadow: "none"
                                    }
                                }}

                                variant='contained'
                            >
                                Authorise
                            </Button>
                        </Tooltip>
                        :

                        <Button
                            disabled={processing || selectedInvoice?.Status === 'AUTHORISED' || !invoicePaymentData?.created_at}
                            // onClick={() => setLastClicked('approve')}
                            onClick={() => setOpen(true)}
                            color='success'
                            size='small'
                            sx={{ textTransform: 'none' }}
                            variant='contained'
                            name="action" value="approve"
                        >
                            Authorise
                        </Button>
                    }
                </>
            }

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                    Authorise Invoice
                </DialogTitle>
                <DialogContent sx={{ py: 2 }}>
                    <Typography variant='h6' sx={{ mb: 1 }}>
                    You authorise this invoice for payment and declare you have checked the payment details to ensure they are correct
                    </Typography>
                    <Stack gap={1} direction={"row"} alignItems={"center"} sx={{ mb: 2 }}>
                        <MUICheckboxInput name='authorise' control={control} value={true} />
                        <Typography variant='body' color={"primary"}>
                        Notify Payment Approvers
                        </Typography>
                    </Stack>
                </DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, borderTop: '1px solid #ccc', py: 2, px: 2 }}>
                        <Button
                            onClick={() => setOpen(false)}
                            variant='outlined'
                            color='error'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            type='submit'
                            name="action" value="approve"
                            color='success'
                            onClick={() => {
                                setLastClicked('approve')
                                setOpen(false)
                            }}
                        >
                            Authorise
                        </Button>
                    </Box>
            </Dialog>

        </>


    );
}
