import { useEffect, useState } from "react"
import instance from "../../../../../auth/utils/useJwt"
import { Box, Card } from "@mui/material"
import MuiDataGrid from "../../../../../components/tables/MUIDataGrid"
import AssignGroup from "../../components/AssignGroup"
import AdminAction from "../../../../../components/PermissionsWrappers/AdminAction"

export default function Groups({
    data
}) {
    const { email } = data
    const [userGroups, setUserGroups] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    const get_groups = () => {
        instance.get(`/admin/groups?user_email=${email}`).then(res => {
            // Add index as index
            res.data.forEach((item, index) => {
                item.indx = index + 1
            })
            setUserGroups(res.data)
            setLoading(false)
        }).catch(err => {
            setError(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        get_groups()
    }, [])


    return (
        <Box>
            <Card sx={{boxShadow: "0 0 0 1px rgba(0, 10, 30, .2)"}}>
            <MuiDataGrid data={userGroups} columns={[
                {
                    field: 'id', headerName: '', width: 20, renderCell: (params) => (
                        <Box>{params.row.indx}</Box>
                    )
                },
                { field: 'name', headerName: 'Name', width: 200 },
                { field: "directMembersCount", headerName: 'Members', width: 100 },
                { field: "adminCreated", headerName: 'Admin Created', width: 130 },
                { field: 'description', headerName: 'Description', flex: 1 }
            ]} loading={loading} error={error}
            noCard={true}
                headerRightButtons={
                    <AdminAction>
                        <Box sx={{ py: 1, px: 2.4 }}>
                            <AssignGroup user_data={data} userGroups={userGroups} setUserGroups={setUserGroups} onGroupChange={() => { }} />
                        </Box>
                    </AdminAction>
                }
            />
            </Card>
        </Box>
    )
}