import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { DialogContentText, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'

import instance from "../../../../auth/utils/useJwt";
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';

export default function DeleteCaseDialog(props) {

    const { open, setOpen, patientData } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const navigate = useNavigate()
    const { register, handleSubmit,
        formState: { errors },
    } = useForm();
    const { patient_id, case_id } = useParams()

    const onSubmit = () => {
        setApiReq({ loading: true, error: null })
        instance.delete(`/cases?patient_id=${patientData.id}&case_id=${parseInt(case_id)}`).then(res => {
            console.log("Done")
            props.showSnackbar("Case deleted successfully", "success")
            props.setOpen(false)
            setApiReq({ loading: false, error: null })
            navigate(`/patients/${patient_id}`)
        }).catch(err => {
            console.log("Error")
            //props.setOpen(false)
            props.showSnackbar("Error deleting case", "error")

            setApiReq({ loading: false, error: "Error deleting case" })
        })
    }

    const onError = (error) => {
        //alert("error")
    }

    const handleClose = () => {
        setOpen(false)
    }


    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Delete Case"
            disabled={apiReq.loading} onFormSubmit={handleSubmit(onSubmit, onError)}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `DELETING` : `DELETE`
                        }
                    </Button>
                </Stack>
            }>

            <DialogContentText sx={{ mb: 1 }}>Are you sure you want to delete this case. Please entry Patient Case id to delete this case.
                "<b>{`${patient_id}-${case_id < 10 ? `0${case_id}` : case_id}`}</b>"
            </DialogContentText>
            <TextField size="small" {...register('patient_case_id', {
                required: true, validate: (value) => {
                    const expectedValue = `${patient_id}-${case_id < 10 ? `0${case_id}` : case_id
                        }`;

                    // Check if the value matches the expected value
                    return value === expectedValue || 'Invalid patient case ID';
                }
            })} name="patient_case_id" placeholder="Patient Case ID"
            fullWidth
                error={Boolean(errors.patient_case_id)}
            />

        </GeneralDialog>
    )
}