import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import instance from '../../../auth/utils/useJwt';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Notifications() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [processing, setProcessing] = React.useState(false)

    const [notifications, setNotifications] = React.useState([])
    const [unreadNotifications, setUnreadNotifications] = React.useState(0)

    const get_notifications = () => {
        instance.get("/notifications").then(res => {
            setNotifications(res.data?.notifications || [])
            setUnreadNotifications(res.data?.unread || 0)
        }).catch(err => {
            console.log(err)
        })
    }

    const setAllRead = () => {
        setProcessing(true)
        instance.patch("/notifications", {
            read: true
        },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setProcessing(false)
                get_notifications()
            }).catch(err => {
                setProcessing(false)
            })
    }

    React.useEffect(() => {

        get_notifications()
    }, [])



    return (
        <><Tooltip title="Open notifications">
            <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{ mr: 1 }}
                id="notification-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Badge badgeContent={
                    unreadNotifications
                } color="primary">
                    <NotificationsOutlinedIcon sx={{ color: "#555" }} />
                </Badge>
            </IconButton>
        </Tooltip>

            <Menu
                id="basic-menu"
                sx={{ minWidth: '200px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ borderBottom: '1px solid #ccc', mb: 1, py: 0.5, px: 2, minWidth: '250px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 500 }} variant='h6'>Notifications</Typography>
                    <Tooltip title="Mark all as read">
                        {processing ? <CircularProgress size={20} /> :
                            <IconButton size="small" onClick={setAllRead} disabled={processing || unreadNotifications === 0}>
                                <CheckCircleOutlineIcon color="primary" />
                            </IconButton>
                        }
                    </Tooltip>
                </Box>
                <Box sx={{ maxHeight: "40vh", overflow: "auto" }}>
                {notifications.map((item, index) => (
                    <MenuItem key={`not-${index}`} sx={{ display: 'flex', my: 1, justifyContent: 'space-between',
                        backgroundColor: item.read ? '#fff' : '#f9f9f9'
                    }} onClick={handleClose}>
                        <Box sx={{ display: 'flex', gap: '7px' }}>
                            {/* <Avatar sx={{ width: '38px', height: '38px' }} /> */}
                            <Box>
                                <Typography sx={{ textTransform: 'capitalize', fontSize: '13px', fontWeight: 550 }}>
                                    {item.type?.replace("_", " ")?.toLowerCase()}
                                    {item.creator ? ` by "${item.creator}" ` : ' '} <span style={{ color: '#4B465C', textTransform: 'lowercase' }}>
                                    [{item.timeago}]
                                    </span>
                                </Typography>
                                <Typography sx={{ fontSize: '11px', color: '#4B465C' }}>{
                                    item.message
                                }</Typography>
                            </Box>
                        </Box>
                        {/* <Box>
                            <Typography sx={{ fontSize: '11px' }}>
                                {item.timeago}
                            </Typography>
                        </Box> */}
                    </MenuItem>
                ))}
                </Box>
                <Box>
                    <Link to="/notifications" style={{ textDecoration: 'none' }}>
                        <MenuItem sx={{ display: 'flex', justifyContent: 'center', py: 1, mt: 0.5 }}>
                            <Typography sx={{ fontSize: '14px', color: '#555', fontWeight: 500, color: "#09f" }}>View All Activity</Typography>
                        </MenuItem>
                    </Link>
                </Box>

            </Menu>
        </>
    );
}