import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';



export function MUICheckboxInput(props) {
  const { value, errors, control, ...restProps } = props;
  
  return (
    <Controller
      name={restProps.name}
      control={control}
      value={value}
      defaultValue={value}
      render={({ field }) => (
        <Checkbox
          {...field}
        {...restProps}
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
          color="primary"
        />
      )}
    />
  );
}