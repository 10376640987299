import React from 'react';
import { useSelector } from 'react-redux';

export default function TZDateView({ date, timezone, outputFormat }){
    const user = useSelector((state) => state.user)
    const userTimezone = !user.use_browser_tz ? timezone || user.timezone || null : null
    const convertToUserTimezone = () => {
        try {
            // Parse the input date string
            const inputDate = new Date(date);
            if (isNaN(inputDate)) {
                throw new Error("Invalid date format");
            }
    
            // Extract the UTC offset if userTimezone is provided, otherwise use the browser timezone
            let timezoneOffset = null;
            if (userTimezone) {
                const match = userTimezone.match(/UTC([+-]\d{2}:\d{2})/);
                if (match) {
                    timezoneOffset = match[1];
                } else {
                    throw new Error("Invalid timezone format");
                }
            }

    
            // Convert UTC offset to minutes
            let offsetMinutes = 0;
            if (timezoneOffset) {
                const [hours, minutes] = timezoneOffset.split(':').map(Number);
                offsetMinutes = hours * 60 + Math.sign(hours) * minutes;
            } else {
                // Default to browser timezone offset in minutes
                offsetMinutes = -new Date().getTimezoneOffset();
            }
    
            // Calculate the converted time
            const convertedDate = new Date(inputDate.getTime() + offsetMinutes * 60000);
    
            // Format the output date
            const dt = convertedDate.toISOString().replace('T', ' ').replace('Z', '');
            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const hour = parseInt(dt.slice(11, 13));
            const ampm = hour >= 12 ? "PM" : "AM";
            const hour12 = (hour % 12 || 12).toString().padStart(2, '0');
            return `${months[parseInt(dt.slice(5, 7))-1]} ${dt.slice(8, 10)} ${dt.slice(0, 4)} ${hour12}:${dt.slice(14, 16)} ${ampm}`;
        } catch (error) {
            console.error("Error:", error.message);
            return null;
        }
    }
    
    return convertToUserTimezone()
}