import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useNavigate } from 'react-router-dom';
import ExportPatient from './dialogs/ExportPatientData';
import ArchivePatient from './components/ArchivePatient';
import { hasWritePermission, hasDeletePermission } from '../../../redux/user';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import DeletePatientDialog from './components/DeletePatient';
import useSnackbar from '../../../components/Dialogs/SnackBar';
import CheckIcon from '@mui/icons-material/Check';
import ArchiveIcon from '@mui/icons-material/Archive';
import AcceptReferralPatientDialog from './components/AcceptReferralPatient';
import { CanModifyPatient, IsPatientAdmin } from '../../../redux/user_roles';

export default function PatientMenu(props) {
  const [SnackbarComponent, showSnackbar] = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const canWrite = useSelector(CanModifyPatient);
  const canDelete = useSelector(IsPatientAdmin);
  const [exportPatient, setExportPatient] = React.useState(false)
  const [archivePatient, setArchivePatient] = React.useState(false)
  const [deletePatient, setDeletePatient] = React.useState(false)
  const [acceptPatient, setAcceptPatient] = React.useState(false)
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCloseDialog = (dt) => {
    if (dt === "export") {
      setExportPatient(true)
    } else if (dt === "archive") {
      setArchivePatient(true)
    }else if (dt === "delete") {
      setDeletePatient(true)
    }else if (dt === "accept") {
      setAcceptPatient(true)
    }
    handleClose()
  }

  const handleClickClose = () => {
    navigate(`/patients/${props.patient_id}/edit`)
  }
  const [accepting, setAccepting] = React.useState(false)

  return (
    <React.Fragment>
      <SnackbarComponent />
      <Backdrop open={accepting} sx={{ zIndex: 9999999}}>
      <CircularProgress color="primary" />
      </Backdrop>
      <ExportPatient open={exportPatient} setOpen={setExportPatient} />
      <ArchivePatient open={archivePatient} setOpen={setArchivePatient} patient_id={props.id} archived={props.archived}
        showSnackbar={showSnackbar}
      />
      <DeletePatientDialog open={deletePatient} setOpen={setDeletePatient} patient_id={props.id} showSnackbar={showSnackbar} />
      <AcceptReferralPatientDialog open={acceptPatient} setOpen={setAcceptPatient} patient_id={props.id} showSnackbar={showSnackbar} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Patient Menu">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {canWrite &&
          <MenuItem onClick={handleClickClose}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit Patient
          </MenuItem>
        }
        {props.romac_id?.slice(0, 1) === "P" && canWrite &&
          <MenuItem onClick={() => handleClickCloseDialog("accept")}>
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
            Accept as new Patient
          </MenuItem>
        }
        {canWrite &&
          <MenuItem onClick={() => handleClickCloseDialog("archive")}>
            <ListItemIcon>
              <ArchiveIcon fontSize="small" />
            </ListItemIcon>
            { props.archived ? "Unarchive Patient" : "Archive Patient"}
          </MenuItem>
        }

        <MenuItem onClick={() => handleClickCloseDialog("export")}>
          <ListItemIcon>
            <ImportExportIcon fontSize="small" />
          </ListItemIcon>
          Export Patient Data
        </MenuItem>
        {canWrite && canDelete &&
          <MenuItem onClick={() => handleClickCloseDialog("delete")}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete Patient
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}
