import { Box, Card, CardContent, LinearProgress, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../../../../auth/utils/useJwt";
import CreateInvoiceNoteDialog from "./CreateInvoiceNote";
import parse from 'html-react-parser';
import PatientCaseInvoiceMeta from "../InvoiceMeta";
import InvoiceMetaTable from "../InvoiceMeta/InvoiceMetaTable";
import { useDispatch } from "react-redux";
import { fetchXEROPaymentDetails } from "../../../../redux/xero/payment_details";
const Subtitle = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'rgb(124, 124, 124)',
    padding: '10px 3px'
}));

export default function PatientCaseInvoiceNotes({
    invoice_status
}) {
    const { invoice_id } = useParams()
    const [invoiceHistory, setInvoiceHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const fetchInvoiceHistory = () => {

        dispatch(fetchXEROPaymentDetails(invoice_id))
        setLoading(true)
        instance.get("/xero/invoice-notes?invoice_id=" + invoice_id).then(res => {
            console.log(res.data)
            setInvoiceHistory(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchInvoiceHistory()
    }, [])

    return (
        <Box sx={{ mt: 2 }}>
            <Card>
                <Box sx={{ fontSize: '16px', display: 'flex', justifyContent: 'space-between', px: 2, py: 1.5, borderBottom: '1px solid #ccc' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Invoice Payment & Notes</Typography>
                    <Stack direction="row" spacing={2}>
                    <PatientCaseInvoiceMeta onUpdate={fetchInvoiceHistory} invoice_status={invoice_status} />
                    <CreateInvoiceNoteDialog invoice_id={invoice_id} onNoteCreated={fetchInvoiceHistory} />
                    </Stack>

                </Box>
                {
                    loading && <LinearProgress />
                }
                <CardContent>
                    {/* { invoiceHistory.meta && invoiceHistory.meta?.expense_type && */}
                    <Subtitle>Payment Details</Subtitle>
                        
                    <Box sx={{ mb: 1, border: '1px solid #ccc', borderRadius: '5px' }}>
                        <InvoiceMetaTable invoice={invoiceHistory.meta} />
                    </Box>
                    {/* } */}


                    <Subtitle>Notes</Subtitle>
                <Box sx={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                }}>
                    {invoiceHistory.notes?.map((item, index) => (
                        <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ fontWeight: 'bold', fontSize: '15px', color: "#09f" }}>{item.user}</div>
                                </div>
                                <div>
                                    <div style={{ color: "#03F", fontSize: '13px' }}>{item.date}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px', fontSize: '13px' }}>{parse(item.note)}</div>
                        </Box>
                    ))}
                </Box>
                </CardContent>

            </Card>
        </Box>
    )
}