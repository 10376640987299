import { Box, ToggleButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CustomInputLabel from "../../../components/forms/CustomInputLabel";


export default function CompletedFilterSwitch({
    completed, setCompleted
}) {

  return (
    <Box>
      <CustomInputLabel sx={{ fontWeight: 700 }}>
        {'Completed'}
      </CustomInputLabel>
      <ToggleButton
      value="check"
      size="small"
      selected={completed}
      onChange={() => {
        setCompleted(!completed);
      }}
    >
      <CheckIcon />
    </ToggleButton>
    </Box>
  );
}
