import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layout/AppLayout";
import { Card, CardContent, Box, Button, CircularProgress } from "@mui/material";
import GoogleMapReact from 'google-map-react';
import instance from "../../auth/utils/useJwt";
import SelectAutocomplete from "./SelectAutocomplete";
import DatePicker from "./DatePicker";
import { DATE_FORMAT } from "../../configs/basic";
import dayjs from 'dayjs';
import BContainer from "../../components/BContainer";
import BreadCrumbs from "../../components/BreadCrumbs";

export default function MapPage() {
    const [filters, setFilters] = useState({
        type: { label: "REFERRAL", value: "REFERRAL" },
        from: dayjs().subtract(8, 'month').format(DATE_FORMAT),
        to: dayjs().format(DATE_FORMAT)
    })
    const defaultProps = {
        center: {
            lat: 7.254473462474085, // Center the map at the equator
            lng: 110.9184213248717  // and the prime meridian for a good world view
        },
        zoom: 1  // Set the zoom level to 1 to show the whole world
    };
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)


    const updateMap = () => {
        setLoading(true)
        instance.get("/patients/countries", {
            params: {
                type: filters.type?.value,
                from: filters.from,
                to: filters.to
            }
        }).then(res => {
            console.log(res)
            setData(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        // setLoading(true)
        // instance.get("/patients/countries").then(res => {
        //     console.log(res)
        //     setData(res.data)
        //     setLoading(false)
        // }).catch(err => {
        //     console.log(err)
        //     setLoading(false)
        // })
        updateMap()
    }, [])

    // List of 5 colors (0-20, 20-50, 50-100, 100-200, 200+)
    const colors = [
        "#2e6542", "#8e7e00", "#ff0000", "#ff00ff", "#0000ff"
    ]

    const get_color = (count) => {
        if (count <= 20) {
            return colors[0]
        } else if (count <= 50) {
            return colors[1]
        } else if (count <= 100) {
            return colors[2]
        } else if (count <= 200) {
            return colors[3]
        } else {
            return colors[4]
        }
    }

    const onChangeInput = (input_name, input_value) => {
        setFilters({ ...filters, [input_name]: input_value })
    }

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Map" },
    ]


    return (
        <AppLayout title="Map" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <BreadCrumbs data={breadcrumbs_v} />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box>
                            <SelectAutocomplete options={
                                [
                                    { label: "REFERRAL", value: "REFERRAL" },
                                    { label: "ARRIVAL", value: "ARRIVAL" },
                                    { label: "DEPARTURE", value: "DEPARTURE" },
                                ]
                            } value={filters.type} onChange={onChangeInput} name="type"
                                label="Type"
                            />
                        </Box>

                        <DatePicker value={filters.from} onChange={(e) => onChangeInput("from", e.value)} label="From" />
                        <DatePicker value={filters.to} onChange={(e) => onChangeInput("to", e.value)} label="To" />
                        <Button onClick={updateMap} variant="outlined" color="primary">Update</Button>
                    </Box>
            </BContainer>
            <Box sx={{ px: 3 }}>
            <Card sx={{ margin: '0 auto', mt: 3 }}>
                {/* <CardHeader title="Maps" sx={{ borderBottom: '1px solid #ddd' }} /> */}
                <CardContent sx={{ px: 0, py: 0 }}>
                    {loading ?
                        <Box sx={{ justifyContent: 'center', display: 'flex', height: '75vh', flexDirection: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box> :
                        <div style={{ height: '75vh', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                {data.map((item, index) => (
                                    <div
                                        key={`map-${index}`}
                                        lat={item.latitude}
                                        lng={item.longitude}
                                        style={{
                                            color: 'white',
                                            background: get_color(item.count),
                                            width: '20px',
                                            height: '20px',
                                            fontSize: '10px',
                                            display: 'inline-flex',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                        }}
                                    >
                                        {item.count}
                                    </div>
                                ))}


                            </GoogleMapReact>
                        </div>
                    }

                </CardContent>
            </Card>
            </Box>
        </AppLayout>
    )
}