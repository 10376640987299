import { Grid, Box, Avatar, Typography, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"

export default function DetailsCard(props) {
    const { data } = props
    
    const [patient_data, setPatientData] = useState([])

    const get_languages = (langs) => {
        var langs_str = ""
        langs.forEach(element => {
            langs_str += ` ${element.name}` + ","
        });

        return langs_str.slice(0, -1);
    }

    useEffect(() => {
        if(data){
        setPatientData([[
            { title: 'Date of Birth', value: data.dob },
            { title: 'Estimated DOB', value: data.dob_text },
            { title: 'Gender', value: data.gender },
            { title: 'Birth Certificate', value: data.has_birth_certificate }
        ],
        [
            { title: 'Height (cm)', value: data.height },
            { title: 'Weight (kg)', value: data.weight },
            { title: 'Religion', value: data.religion },
            { title: 'Primary Region', value: data.region }
        ],
        [
            { title: 'Citizenship', value: data.citizenship },
            { title: 'Medical Classification', value: data.medical_classification },
            { title: 'Languages Spoken', value: get_languages(data.languages) },
            { title: 'Understands English', value: data.understands_english }
        ]])
    }
    }, [data])


    if(!data) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
            </Box>
        )
    }
        
        
    

    return (
        <Box sx={{ py: 3 }}>
        <Grid container spacing={1}>
            <Grid item md={3}>
                <Box sx={{ display: 'flex' }}>
                    <Box>
                        <Avatar alt={ data.first_name } src={data.avatar} sx={{ width: 82, height: 82 }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', px: 2 }}>
                        <Typography variant="subtitle2"  color={"primary"}>{data.romac_id }</Typography>
                        <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", lineHeight: 1.2, fontWeight: 'bold' }}>{ data.first_name } {data.last_name}
                         </Typography>
                        <Typography variant="caption" sx={{ my: 0.6 }}>
                        Alias: { data.alias ? `${data.alias}` : '-' }
                        

                        </Typography>
                        <Typography variant="caption" sx={{ padding: 0, margin:0 }} color={"primary"}>{ data.country }</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={9}>
                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>DETAILS</Typography>
                <Grid container spacing={1}>
                    {
                        patient_data.map((group_arr, group_ind) => {
                            return (
                                <Grid key={`patient-data-g-${group_ind}`} item md={4}>
                                    {
                                        group_arr.map((det, det_index) => (
                                            <Box key={`patient-data-${group_ind}-${det_index}`} sx={{ display: 'flex' }}>
                                                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92, 0.8)", fontWeight: 400, fontSize: '13px', minWidth: '130px' }}>{det.title}:</Typography>
                                                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{det.value}</Typography>
                                            </Box>
                                        ))
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                <Box sx={{ display: 'flex', mt: 1, gap: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>Contact number (eg. Whatsapp no.):</Typography>
                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{data.whatsapp_number}</Typography>
                </Box>
                </Grid>
                <Grid item md={8}>
                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px', mt: 1 }}>Contact Details:</Typography>
                <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{
                    data.address
                }</Typography>
                </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Box>
    )
}