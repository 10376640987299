import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import GeneralDialog from '../../../../components/Dialogs/CustomDialog'
import instance from '../../../../auth/utils/useJwt'
import { Alert, Box, CircularProgress, MenuItem, Select, Stack, TextField } from '@mui/material'
import CustomInputLabel from '../../../../components/forms/CustomInputLabel'

export default function NewTrainingFolderDialog({
    onFolderCreated, folder_id
}) {
    const [folderName, setFolderName] = useState("")
    const [folderThumbnail, setFolderThumbnail] = useState("")
    const [excerpt, setExcerpt] = useState("")
    const [driveId, setDriveId] = useState(null)
    const [allFiles, setAllFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [open, setOpen] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (folder_id) {
            setOpen(true)
        }
    }, [folder_id])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onInputChange = (e) => {
        setFolderName(e.target.value)
    }

    const onSave = async () => {
        setLoading(true)
        setError(null)
        var res;
        if (folder_id){
            res = await instance.patch(`/admin/training/folder`, { name: folderName, thumbnail: folderThumbnail,
                excerpt: excerpt,
                folder_id: folder_id })
        }else{
            res = await instance.post(`/admin/training/folder`, { name: folderName, thumbnail: folderThumbnail,
                excerpt: excerpt
             })
        }

        if (res.status !== 200) {
            setLoading(false)
            setError("Unable to save folder")
            return
        }

        setLoading(false)
        setSuccess(true)
        onFolderCreated()
        setTimeout(() => {
            setSuccess(false)
            setOpen(false)
        }, 1000)
    }

    const fetchFolder = () => {
        instance.get(`/admin/training/folder?folder_id=${folder_id}`).then(res => {
            console.log(res.data)
            console.log("fetched folder")
            setFolderName(res.data.name)
            setFolderThumbnail(res.data.thumbnail)
            setExcerpt(res.data.excerpt)
        }).catch(err => {
            console.log(err)
        })
    }


    const fetchThumbnailFiles = () => {
        instance.get("/training/files").then(res => {
            setAllFiles(res.data.files)
            setDriveId(res.data.driveId)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchThumbnailFiles()
    }, [])

    useEffect(() => {
        if (folder_id) {
            fetchFolder()
        }
    }, [folder_id])

    console.log("Folder ID", folder_id)


    return (
        <>
            <div onClick={handleClickOpen} style={{
                color: 'blue', cursor: 'pointer'
            }}>
                New Folder
            </div>
            <GeneralDialog open={open} handleClose={handleClose} title={ folder_id ? "Edit Folder" : "New Folder"}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} color="primary" size='small'>
                            Cancel
                        </Button>
                        <Button onClick={onSave} color="primary" size='small' variant='contained'>
                            {loading ? <CircularProgress size={20} /> : "Save"}
                        </Button>
                    </Stack>
                }>
                <Box>
                    <CustomInputLabel>
                        Folder Name
                    </CustomInputLabel>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        type="text"
                        fullWidth
                        value={folderName}
                        onChange={onInputChange}
                    />
                </Box>

                <Box sx={{ mt: 1 }}>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        <CustomInputLabel>
                            Thumbnail
                        </CustomInputLabel>
                        <a target='_blank'
                            rel="noreferrer"
                            href={`https://drive.google.com/drive/folders/${driveId}`}>Open Google Drive</a>
                    </Stack>
                    
                    <Select fullWidth value={folderThumbnail}
                     onChange={(e) => setFolderThumbnail(e.target.value)}>
                        {
                            allFiles.map(file => (
                                <MenuItem value={file.fileViewLink}>
                                    <img src={file.fileViewLink} alt={file.name} style={{
                                        maxWidth: '50px', maxHeight: '100%', border: '1px solid #ccc', marginRight: '10px'
                                    }} />
                                    <div>{file.name}</div>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Box>

                <Box sx={{ mt: 1 }}>
                    <CustomInputLabel>
                        Excerpt
                    </CustomInputLabel>
                    <TextField multiline fullWidth rows={3} value={excerpt} onChange={(e) => setExcerpt(e.target.value)}
                    placeholder='Excerpt (A short details/drescription about this module/folder)'
                    />
                </Box>


                {error && <Alert severity="error">{error}</Alert>}
            </GeneralDialog>
        </>
    )
}