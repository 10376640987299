import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People'; // For Total Patients
import AssignmentIcon from '@mui/icons-material/Assignment'; // For Total Cases
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // For Pending Cases
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // For Current Cases
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // For Completed Cases
import CancelIcon from '@mui/icons-material/Cancel'; // For Rejected Cases

import StatisticCard from "./comps/StatCard";
import { Box, Grid } from '@mui/material';

export default function Stats(props) {
    const { cases, cases_status, patients } = props.data
    const data = [
        {
            title: 'Total Patients',
            value: patients?.length,
            data: patients,
            IconComponent: PeopleIcon,
            color: { background: '#e3f2fd', text: '#0d47a1', icon: '#2196f3' }
        },
        {
            title: 'Total Cases',
            value: cases?.length,
            data: cases,
            IconComponent: AssignmentIcon,
            color: { background: '#fffde7', text: '#f57f17', icon: '#ffeb3b' }
        },
        {
            title: 'Pending Cases',
            value: cases_status?.PENDING?.length,
            data: cases_status?.PENDING,
            IconComponent: HourglassEmptyIcon,
            color: { background: '#fce4ec', text: '#ad1457', icon: '#f48fb1' }
        },
        {
            title: 'Current Cases',
            value: cases_status?.CURRENT?.length,
            data: cases_status?.CURRENT,
            IconComponent: PlayCircleOutlineIcon,
            color: { background: '#e8f5e9', text: '#1b5e20', icon: '#66bb6a' }
        },
        {
            title: 'Completed Cases',
            value: cases_status?.COMPLETED?.length,
            data: cases_status?.COMPLETED,
            IconComponent: CheckCircleOutlineIcon,
            color: { background: '#e0f7fa', text: '#006064', icon: '#26c6da' }
        },
        {
            title: 'Rejected Cases',
            value: cases_status?.REJECTED?.length,
            data: cases_status?.REJECTED,
            IconComponent: CancelIcon,
            color: { background: '#ffebee', text: '#b71c1c', icon: '#ef5350' }
        },
    ]

    return (

        <Box>
            {
                data.map((item, index) => (
                    <Box sx={{ mb: 2 }} key={index}>
                        <StatisticCard {...item} />
                        
                    </Box>
                ))
            }

        </Box>
        
    )
}
