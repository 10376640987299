import React from 'react';
import {
    TextField,
    Grid,
    Box
} from '@mui/material';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import instance from '../../../auth/utils/useJwt';
import DatePicker from '../../../components/forms/DatePicker';
import NewContactDialog from './NewContact';
import MUIAutocomplete from './components/MUIAutocomplete';
import IconToolTip from '../../../components/Dialogs/IconToolTip';

const CustomInputLabelTooltip = ({ children, tooltip }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CustomInputLabel>{children}</CustomInputLabel>
            <IconToolTip text={tooltip} />
        </Box>
    )
}

export default function InvoiceCreationBasicForm(props) {
    const { invoice_id, register, errors, setValue, watch, control } = props
    const [xeroContacts, setXeroContacts] = React.useState([])
    const [xeroCurrencies, setXeroCurrencies] = React.useState([])

    const fetchData = (api_path, setSt) => {
        instance.get(api_path).then(({ data }) => {
            console.log(data)
            setSt(data)
        }
        ).catch(err => {
        })
    }
    React.useEffect(() => {
        fetchData('/xero/contacts', setXeroContacts)
        fetchData('/xero/currencies', setXeroCurrencies)
    }, [])

    const contact_condtion = () => {
        if (invoice_id === "new") {
            return true
        }
        return xeroContacts.length > 0 && watch("Contact") ? true : false
    }

    return (
        <div>
            <Grid container spacing={2} columns={10} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex' }}>
                        <CustomInputLabelTooltip tooltip={"Select a contact or add new contact."}>Supplier</CustomInputLabelTooltip>
                        </Box>
                        <NewContactDialog onNewContact={(contact) => {
                            setXeroContacts([...xeroContacts, contact])
                            setValue("Contact", contact.id)
                        }} />
                    </Box>
                    <MUIAutocomplete options={
                        // Contacts as options (convert to label and value)
                        xeroContacts.map((contact) => ({
                            label: contact.name,
                            value: contact.id
                        }))
                    }
                    name="Contact"
                    control={control}
                    register={register}
                    errors={errors}
                    required
                    />
                    
                </Grid>
                <Grid item xs={12} sm={2}>
                <CustomInputLabelTooltip tooltip={<div>The Issue Date refers to the date when the invoice is issued by the supplier.<br />
                        Note: The date you enter this invoice will be recorded automatically.</div>
                }>Issue Date</CustomInputLabelTooltip>
                    <DatePicker name="Date" onChange={(e) => setValue("Date", e.value)} value={watch("Date")} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <CustomInputLabelTooltip 
                    tooltip="The Due Date is the final date by which the payment for the invoice must be made."
                    >Due Date
                    </CustomInputLabelTooltip>

                    <DatePicker name="DueDate" onChange={(e) => setValue("DueDate", e.value)} value={watch("DueDate")} />
                </Grid>
                <Grid item xs={12} sm={2}>
                <CustomInputLabelTooltip 
                    tooltip={<div>Invoice number is a unique number assigned to each invoice, it is auto generated.
<br />
Note: You should overwrite this with the supplier invoice number whenever provided.</div>}>Invoice Number</CustomInputLabelTooltip>
                    <TextField
                        {...register("InvoiceNumber")}
                        size='small'
                        error={!!errors.InvoiceNumber}
                        helperText={errors.InvoiceNumber?.message}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    {/* <CustomInputLabelTooltip
                    tooltip={"The Reference field is used to record any additional information that may be required by the customer, such as a 'Patient Case Number'. It is also used to identify the invoice in the Xero system."}
                    >Reference</CustomInputLabelTooltip>
                    <TextField
                        size='small'
                        {...register("Reference", { required: 'Reference is required' })}
                        disabled
                        fullWidth
                    /> */}
                    <CustomInputLabelTooltip
                    tooltip={"The currency of the invoice (or when converted to AUD or NZD for foreign invoices)."}
                    >Currency</CustomInputLabelTooltip>
                    <MUIAutocomplete options={
                        // Contacts as options (convert to label and value)
                        xeroCurrencies.map((currency) => ({
                            label: currency.description,
                            value: currency.code
                        }))
                    } name="CurrencyCode" control={control} register={register} errors={errors} required />
                </Grid>

            </Grid>

            {/* <Grid container spacing={2} columns={10} sx={{ mt: 0.1 }}>

                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={2}>
                    <CustomInputLabelTooltip
                    tooltip={"The currency of the invoice."}
                    >Currency</CustomInputLabelTooltip>
                    <MUIAutocomplete options={
                        // Contacts as options (convert to label and value)
                        xeroCurrencies.map((currency) => ({
                            label: currency.description,
                            value: currency.code
                        }))
                    } name="CurrencyCode" control={control} register={register} errors={errors} required />
                </Grid>
            </Grid> */}
        </div>
    );
}
