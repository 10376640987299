import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../auth/utils/useJwt';

// Create an async thunk to fetch occupations
export const fetchOccupations = createAsyncThunk('occupations/fetchOccupations', async () => {
  try {
    const response = await instance.get('/occupations');
    return response.data; // Assuming the API response is an array of occupations
  } catch (error) {
    throw error;
  }
});

export const Occupation = createSlice({
  name: 'occupations',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    addOccupation: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOccupations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOccupations.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchOccupations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addOccupation } = Occupation.actions;

export default Occupation.reducer;
