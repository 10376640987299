import { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Divider, Grid, Skeleton, Stack } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import dayjs from 'dayjs';
import FiltersForm from "./FiltersForm";
import instance from "../../../auth/utils/useJwt";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { DATE_FORMAT } from "../../../configs/basic";
import BContainer from "../../../components/BContainer";
import DataGridTable from "./DataTable";
import { Link } from "react-router-dom";



function ProcessCard({
    data, title
}) {
    return (
        <Card>
                        <Box>
                            <Typography variant="h6" sx={{ py: 1, px: 2, fontWeight: 'bold' }}>
                                {title}
                            </Typography>
                        </Box>
                        <Box>
                            <Divider />
                            {
                                data?.map((item, index) => (
                                    <Box>
                                        <Stack direction={"row"} justifyContent={"space-between"} sx={{ p: 1, px: 2 }}>
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                {item[0]} ({item[2]})
                                            </Typography>

                                            <Stack direction={"row"} spacing={2}>
                                                {/* <Stack direction={"row"} spacing={1} sx={{ fontSize: '11px' }}>
                                                    <Typography sx={{ color: "#aaa" }}> Amount due</Typography>
                                                    <Typography sx={{ color: "#666" }}> {item[1]?.toLocaleString()}</Typography>
                                                </Stack> */}
                                                <Button color="primary" size="small" sx={{
                                                    textTransform: 'capitalize',
                                                }} component={Link} to={`/reports/insurance/details?duration=${item[0]}`}>
                                                    Review
                                                </Button>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                ))
                            }
                        </Box>

                    </Card>
    )
}

const YearList = () => {
    const startYear = 2004;
    const currentYear = new Date().getFullYear() + 1;
    const years = [];

    for (let i = startYear; i < currentYear; i++) {
        years.push({ label: `${i} / ${String(i + 1).slice(2)}`, value: `${i} / ${String(i + 1).slice(2)}` });
    }
    return years.reverse()
}

export default function InsuranceGroupedReports() {
    const [insuranceReportsData, setInsuranceReportsData] = useState(null)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })


    const [filters, setFilters] = useState({
        financial_year: YearList()[0],
        from: dayjs().subtract(12, 'month').format(DATE_FORMAT),
        to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
    })

    const onClear = () => {
        setFilters({
            financial_year: YearList()[0],
            from: dayjs().subtract(8, 'month').format(DATE_FORMAT),
            to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
        })
    }

    const fetchReports = () => {
        setApiReq({ loading: true, error: null })
        instance.post("/reports/insurance", filters).then(res => {
            // add index to id

            setInsuranceReportsData(res.data)
            console.log(res.data)
            setApiReq({ loading: false, error: null })
        }).catch(err => {

            setApiReq({ loading: false, error: "Error fetching records" })
        })
    }

    useEffect(() => {
        fetchReports()
    }, [])

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Insurance" },
    ]

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                {apiReq.loading ?
                    <Box>
                        <Stack direction={"column"} spacing={1} sx={{ p: 1, px: 2 }}>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        </Stack>
                    </Box>
                    :
                    <Stack direction={"column"} spacing={2}>
                        <ProcessCard data={insuranceReportsData?.current} title={"Current Reports"} />
                        <ProcessCard data={insuranceReportsData?.completed} title={"Completed Reports"} />

                    </Stack>
                }



            </Box>

        </AppLayout>
    )
}