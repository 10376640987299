import { useEffect, useState } from "react"
import instance from "../../../../../auth/utils/useJwt"
import { Box, Button, CircularProgress, IconButton, LinearProgress, Stack, TextField, Typography } from "@mui/material"
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GoogleIcon from '../../../../../assets/images/icons/google-drive.png'
import DeleteAttachment from "../../components/DeleteAttachment";
export default function GoogleDriveFiles({
    invoiceId, appendAttachments, attachments, onDelete
}) {
    const [driveId, setDriveId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [allFiles, setAllFiles] = useState([])
    const [attachmentFileMap, setAttachmentFileMap] = useState({})
    const [files, setFiles] = useState([])
    const [previousAttachmentIds, setPreviousAttachmentIds] = useState([])
    const [updating, setUpdating] = useState(null)

    const get_files = () => {
        setLoading(true)
        instance.get(`/xero/invoices/${invoiceId}/drive-files`).then(res => {
            setLoading(false)
            console.log(res.data)
            setAllFiles(res.data.files)
            setFiles(res.data.files)
            setDriveId(res.data.drive_id)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const add_file_to_invoice = (fileId) => {
        setUpdating(fileId)
        instance.post(`/xero/invoices/${invoiceId}/drive-files`, {
            file_id: fileId
        }).then(res => {
            setUpdating(null)
            setPreviousAttachmentIds(prev => [...prev, fileId])

            appendAttachments(res.data)
            


        }).catch(err => {
            console.log(err)
            setUpdating(null)
        })
    }

    useEffect(() => {
        get_files()
    }, [])

    useEffect(() => {
        setFiles(allFiles.filter(file => file.name.toLowerCase().includes(searchText.toLowerCase())))
    }, [searchText])

    useEffect(() => {
        setPreviousAttachmentIds(attachments?.map(attachment => attachment.google_drive_file_id))
        if (attachments?.length > 0) {
            setAttachmentFileMap(attachments.reduce((acc, attachment) => {
                acc[attachment.google_drive_file_id] = attachment.id
                return acc
            }, {}))
        }
    }, [attachments])

    const get_file_attachment_id = (fileId) => {
        return attachments.find(attachment => attachment.google_drive_file_id === fileId)?.id
    }

    const onDeleteAttachment = (attachmentId) => {
        onDelete(attachmentId)
    }

    return (
        <div>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    <TextField size="small" fullWidth label="Search"
                    type="search"
                    value={searchText} onChange={(e) => {
                        setSearchText(e.target.value)
                    }} />

                    <Button onClick={get_files} variant="outlined" color="primary">
                        { loading ? <CircularProgress size={20} /> : "Refresh" }
                    </Button>

                    <Button component="a" href={`https://drive.google.com/drive/folders/${driveId}`} target="_blank"
                    variant="outlined" color="primary">
                        {/* Show drive mg */}
                        <img src={GoogleIcon} alt="Google Drive" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </Stack>
            { loading ? <LinearProgress /> : null }

            {
                files.length === 0 ? <Box sx={{ textAlign: 'center', color: '#777', mt: 2 }}>
                    <Typography variant="body2">No files found</Typography>

                </Box> : null}
            


            {
                files.map((file, index) => (
                    <Box key={index} sx={{
                        py: 0.1,
                        border: '1px solid #ccc', my: 1, px: 1, borderRadius: '5px',
                        color: previousAttachmentIds.includes(file.id) ? 'green' : 'black',
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                        "&:hover": {
                            backgroundColor: '#f9f9f9'
                        }
                    }}>
                        <a href={file.webViewLink}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        target="_blank" rel="noreferrer">
                            {file.name}
                        </a>
                        { updating === file.id ? <CircularProgress size={20} sx={{ my: 1 }} /> :
                        <>
                        {previousAttachmentIds.includes(file.id) ?
                            <DeleteAttachment attachmentId={attachmentFileMap[file.id]}
                            onDelete={() => onDeleteAttachment(attachmentFileMap[file.id])} />
                            :
                            <IconButton onClick={() => add_file_to_invoice(file.id)} disabled={updating !== null}>
                                <ControlPointIcon color="primary" />
                            </IconButton>
                        }</>
                    }
                    </Box>
                ))
            }
        </div>
    )
}