import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Creating the async thunk for fetching patients
export const fetchPatients = createAsyncThunk('patients/fetchPatients', async (fetch_type) => {
    try {
        const response = await instance.get(`/patients?type=${fetch_type}`);  // Changed API endpoint to '/patients'
        return response.data; // API response is an array of patients
    } catch (error) {
        throw error;
    }
});

const sortData = ({ key, direction }, data) => {
    if (!key || !data || !Array.isArray(data)) {
        return [...data];
    }

    return [...data].sort((a, b) => {
        let aValue = a[key];
        let bValue = b[key];

        if (key === 'romac_id') {
            aValue = parseInt(aValue.split('-')[1], 10);
            bValue = parseInt(bValue.split('-')[1], 10);
        }

        if (aValue < bValue) {
            return direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return direction === 'asc' ? 1 : -1;
        }
        return 0;
    });
};

const addUnique = (collection, item, key) => {
    const formattedItem = { label: item[key], value: item[key] };
    const exists = collection.some(obj => obj.label === formattedItem.label && obj.value === formattedItem.value);
    if (!exists) {
        collection.push(formattedItem);
    }
}
const toStrList = (arr) => {
    const av = []
    arr.map(item => av.push(item.value))
    return av
}

const paginateData = (allData, currentPage, perPage) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    return allData.slice(startIndex, endIndex);
}

const filterDataBySearch = (allData, searchInput) => {
    const searchQuery = searchInput.toLowerCase();
    return allData.filter(patient => {
        return (
            patient.romac_id?.toLowerCase().includes(searchQuery) ||
            patient.first_name?.toLowerCase().includes(searchQuery) ||
            patient.last_name?.toLowerCase().includes(searchQuery)
        );
    });
}

// Creating the slice for patients
export const Patients = createSlice({
    name: 'patients',
    initialState: {
        allData: [],
        data: [],
        archived: "no",
        editRow: null,
        loading: false,
        error: null,
        perPage: 10,
        currentPage: 1,
        totalPages: 0,
        sortConfig: {
            key: null,
            direction: 'asc',
        },
        searchInput: "",
        filters: [

        ],
        queryArg: localStorage.getItem("queryArg") || "active",
        queryArgs: [
            { label: "All", value: "all" },
            { label: "Active", value: "active" },
            { label: "Archived", value: "archived" },
            // { label: "Starred", value: "starred" },
        ],
        
        selectedFilters: null
    },
    reducers: {
        addPatient: (state, action) => {
            state.allData.push(action.payload);
            //state.data = paginateData(state.allData, state.currentPage, state.perPage);
        },
        setPatientsData: (state, action) => {
            state[action.payload.key] = action.payload.value;

            if (action.payload.key === "queryArg") {
                localStorage.setItem("queryArg", action.payload.value);
            }

            if (action.payload.key === 'perPage' || action.payload.key === 'allData') {
                state.totalPages = Math.ceil(state.allData.length / state.perPage);
                //state.data = paginateData(state.allData, state.currentPage, state.perPage);
            }

            if (state.sortConfig?.key) {
                state.allData = sortData(state.sortConfig, state.allData);
                //state.data = paginateData(state.allData, state.currentPage, state.perPage);
            }

            if (state.searchInput) {
                state.data = filterDataBySearch(state.allData, state.searchInput);
                //state.data = paginateData(state.data, state.currentPage, state.perPage);
            }
        },
        changePage: (state, action) => {
            state.currentPage = action.payload;
            //state.data = paginateData(state.allData, state.currentPage, state.perPage);
        },
        setPatientsDataFiltered: (state, action) => {
            // Assuming `toStrList` is defined somewhere
            const shouldIncludeItem = (item, key) => {
                return !action.payload[key]?.length || toStrList(action.payload[key]).includes(item[key]);
            }

            const filteredData = state.allData.filter(item =>
                shouldIncludeItem(item, 'region') &&
                shouldIncludeItem(item, 'country') &&
                shouldIncludeItem(item, 'medical_classification') &&
                shouldIncludeItem(item, 'status')
            );

            state.data = filteredData //paginateData(filteredData, 1, state.perPage); // Always start from page 1 when filters applied
            state.currentPage = 1;
            state.selectedFilters = action.payload;
        },
        togglePatientStarred: (state, action) => {
            const index = state.allData.findIndex(item => item.id === action.payload.id)
            state.allData[index].starred = action.payload.starred

            const data_index = state.data.findIndex(item => item.id === action.payload.id)
            state.data[data_index].starred = action.payload.starred
        },
        clearFilters: (state, action) => {
            state.selectedFilters = null;
            //state.data = paginateData(state.allData, 1, state.perPage); // Clearing filters should reset to page 1
            state.currentPage = 1;
            state.totalPages = Math.ceil(state.allData.length / state.perPage);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPatients.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPatients.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
                state.mainData = action.payload;
                state.data = action.payload;
                state.currentPage = 1;
                // if (action.payload.length > state.perPage) {
                //     state.data = action.payload.slice(0, state.perPage);
                //     state.totalPages = Math.ceil(action.payload.length / state.perPage);
                // } else {
                //     state.data = action.payload;
                //     state.totalPages = 1;
                // }

                
            
                const regions = [];
                const countries = [];
                const medical_classifications = [];
                const statuses = [];
            
                action.payload?.forEach((item) => {
                    if (item.region) addUnique(regions, item, 'region');
                    if (item.country) addUnique(countries, item, 'country');
                    if (item.medical_classification) addUnique(medical_classifications, item, 'medical_classification');
                    if (item.status) addUnique(statuses, item, 'status');
                });
        
                state.filters = [
                    {
                        label: "Region", id: "region", selectedFilters: [], options: regions
                    },
                    {
                        label: "Country", id: "country", selectedFilters: [], options: countries
                    },
                    {
                        label: "Medical Classification", id: "medical_classification", selectedFilters: [], options: medical_classifications
                    },
                    {
                        label: "Status", id: "status", selectedFilters: [], options: statuses
                    }
                ]
            })
            .addCase(fetchPatients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addPatient, changePage, setPatientsData, setPatientsDataFiltered, clearFilters, togglePatientStarred } = Patients.actions;

export default Patients.reducer;

