import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import { Box, Typography, Card, CardHeader, CardContent } from "@mui/material";
import BContainer from "../../components/BContainer";
import BreadCrumbs from "../../components/BreadCrumbs";


export default function Support() {
    return (
        <AppLayout title="Support" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Support" }
                ]} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Card sx={{ maxWidth: '800px', margin: '0 auto', mt: 2 }}>
                    <CardHeader title="Support" sx={{ borderBottom: '1px solid #ddd' }} />
                    <CardContent sx={{ px: 5 }}>

                        <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%', py: 10 }}>
                            <Typography variant="body" color={"primary"} sx={{ fontWeight: 'bold' }}>
                                Coming Soon
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </AppLayout>
    )
}