export function generateYearsList() {
    const currentYear = new Date().getFullYear();
    let startYear = 2004;
    let yearsList = [];

    while (startYear < currentYear) {
        let nextYearShort = (startYear + 1 - 2000).toString().padStart(2, '0');
        let formattedYear = `${startYear}/${nextYearShort}`;
        yearsList.push(formattedYear);
        startYear++;
    }
    return yearsList;
}