const ADMIN_PAGES_ROLES = ["Admin"]
const PATIENT_ROLES = [...ADMIN_PAGES_ROLES, "Patient Manager", "Manager", "Patient Admin", "Patient Viewer", "Viewer"]
const CASE_ROLES = [...ADMIN_PAGES_ROLES, "Case Manager", "Manager", "Case Admin", "Case Viewer", "Viewer"]
const DONATION_ROLES = [...ADMIN_PAGES_ROLES, "Donations Manager", "Donations Admin", "Donations Viewer"]
const REFERRAL_ROLES =  [...ADMIN_PAGES_ROLES, "Referral Manager", "Referral Admin", "Referral Viewer"]
const EXPENSES_ROLES =  [...ADMIN_PAGES_ROLES, "Expenses Manager", "Expenses Admin", "Expenses Viewer"]
const REPORTS_ROLES = [...ADMIN_PAGES_ROLES, "Reports Viewer", "Reports Manager"]

const MANAGER_ROLE = ["Manager"]
const EXPENSE_AUTHORIZER_ROLE = ["Expenses Admin", "Admin"]

const ALL_ROLES = [
    ...ADMIN_PAGES_ROLES,
    ...PATIENT_ROLES,
    ...CASE_ROLES,
    ...DONATION_ROLES,
    ...REFERRAL_ROLES,
    ...EXPENSES_ROLES,
    ...REPORTS_ROLES,
]



export {
    ADMIN_PAGES_ROLES, PATIENT_ROLES, CASE_ROLES, DONATION_ROLES, REFERRAL_ROLES, EXPENSES_ROLES,
    ALL_ROLES, REPORTS_ROLES, MANAGER_ROLE, EXPENSE_AUTHORIZER_ROLE
}