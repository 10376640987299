import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDistricts } from '../../../../redux/districts';
import CustomInputLabel from './components/CustomInputLabel';
import { useForm, Controller } from 'react-hook-form';
import instance from '../../../../auth/utils/useJwt';
import DeleteDialog from './components/DeleteDialog';
import { fetchRegions } from '../../../../redux/region';
import DataGridTableLists from './components/DataTableLists';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import FormButtons from './components/FormButtons';
import ErrorMessage from './components/ErrorMessage';

export default function Districts({
    open, setOpen
}) {

    const { data, loading } = useSelector(state => state.districts)
    const [selectedRow, setSelectedRow] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchDistricts())
        }
    }, [])

    const handleEdit = (row) => {
        setSelectedRow(row)
        setOpen(true)
    }

    const changeOpen = (ot) => {
        setOpen(false)
        setSelectedRow(null)
    }

    const handleDelete = (did) => {
        setDeleteId(did)
    }


    return (
        <Box>
            <DataGridTableLists data={data} loading={loading}
                handleEdit={handleEdit} handleDelete={handleDelete}
                cols={[{ field: 'name', headerName: 'Name', flex: 1 },
                { field: 'address', headerName: 'Address', flex: 1 }, { field: 'region', headerName: 'Region', flex: 1 }]} />

            <DeleteDialog heading="Delete Region" id={deleteId} onDelete={() => dispatch(fetchDistricts())} api_url={"/districts"} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mx: 2 }}>
                {/* <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>Add</Button> */}
                <PopUp open={open} setOpen={changeOpen} editRow={selectedRow} />
            </Box>
        </Box>
    );
}


function PopUp(props) {
    const { open, setOpen, editRow } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const regions = useSelector(state => state.regions).data

    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
    }, [])

    const dispatch = useDispatch()
    const { register, reset, handleSubmit, watch, control,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (editRow) {
            reset(editRow)
        } else {
            reset({ name: "", id: null, address: "", region: null })
        }
    }, [editRow])


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })
        if (!editRow) {
            instance.post("/districts", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchDistricts())
                reset({ name: "", id: null, address: "", region: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err?.response?.data ? err.response.data : "Error adding new district" })
            })
        } else {
            instance.patch("/districts", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchDistricts())
                reset({ name: "", id: null, address: "", region: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err?.response?.data ? err.response.data : "Error updating district" })
            })
        }
    }
    const onError = (err) => {
        console.log(err)
    }


    return (
        <GeneralDialog open={open} handleClose={setOpen} title={editRow ? 'Edit District' : 'Add District'}
            onFormSubmit={handleSubmit(onSubmit, onError)} disabled={apiReq.loading}
            actions={<FormButtons loading={apiReq.loading} setOpen={setOpen} />}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <CustomInputLabel>District</CustomInputLabel>
                    <TextField {...register('name', { required: true })} error={Boolean(errors.name)} size='small' fullWidth />
                </Grid>
                <Grid item md={6}>
                    <CustomInputLabel>Region</CustomInputLabel>
                    <Controller
                        name="region"
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'Region is required!' }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                options={regions.map(({ id, name }) => ({ label: name, value: id }))}
                                //getOptionLabel={(option) => option.title}
                                value={value}
                                onChange={(_, newValue) => onChange(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" variant="outlined" fullWidth error={Boolean(errors.region || !watch("region"))} />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
                <CustomInputLabel>Address</CustomInputLabel>
                <TextField {...register('address', { required: true })} multiline rows={3} error={Boolean(errors.address)} size='small' fullWidth />
            </Box>
            <ErrorMessage error={apiReq.error} />
        </GeneralDialog>
    );
}
