import * as React from 'react';
import MuiDataGrid from '../../../components/tables/MUIDataGrid';
import ExportInsuranceReports from './ExportDialog';
const columns = [
    { field: 'patient_id', headerName: 'Patient ID', width: 100 },
    { field: "name", headerName: "Patient Name", width: 200 },
    { field: "status", headerName: "Status", width:80,visible: false},
    { field: "archived", headerName: "Archived", width:150,visible: false, renderCell: (params) => {
        return params.row.archived ? "Yes" : "No"
    }},
    { field: 'accompanying_guardian', headerName: 'Accompanying Guardian', width: 120 },
    { field: 'region', headerName: 'City in Australia', width: 120 },
    { field: 'country', headerName: 'Country' },
    { field: 'contact_details', headerName: 'Contact Details', flex: 1 },
    { field: 'arrival_date', headerName: 'Arrival Date', width: 100 },
    { field: 'departure_date', headerName: 'Departure Date', width: 120 },
    { field: "hosting_family_name", headerName: "Hosting Family Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1, visible: false },
];


export default function DataGridTable(props) {
    const { loading, data } = props
    return (
        <MuiDataGrid noCard={true} data={data || []} columns={columns} loading={loading}
        headerRightButtons={
            <ExportInsuranceReports filters={props.filters} />
        }
        />
    );
}