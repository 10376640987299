import { Button, IconButton, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form";

import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitals } from "../../../../../redux/medical/hospital";
import instance from "../../../../../auth/utils/useJwt";

import { setCaseValue } from "../../../../../redux/case/case";
import MUIAutocomplete from "../../../../../components/forms/MUIAutocomplete";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";
import GeneralDialog from "../../../../../components/Dialogs/CustomDialog";
import CaseAction from "../../../../../components/PermissionsWrappers/CaseAction";

export default function HospitalSelection() {

    const dispatch = useDispatch()

    const patient_case = useSelector(state => state.patient_case)
    const case_data = patient_case.data
    const is_readonly = patient_case.is_readonly

    const hospitals = useSelector(state => state.hospitals).data


    useEffect(() => {
        if (hospitals.length === 0) {
            dispatch(fetchHospitals())
        }

        // if no hospital, add empty hospital
        if (case_data?.hospital?.length === 0) {
            dispatch(setCaseValue({
                name: 'hospital', value: [{
                    id: null,
                    hospital: null,
                    nhi: null,
                }]
            }))
        }

    }, [])


    const onChangeInput = (index, key, val) => {
        const newHospital = case_data?.hospital?.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    [key]: val
                }
            }
            return item
        })
        dispatch(setCaseValue({
            name: 'hospital', value: newHospital
        }))
    }

    return (

        <Box>
            <CustomInputLabel>Hospital <CaseAction> {` | `} <NewHospital /></CaseAction>
            </CustomInputLabel>
  

            {case_data?.hospital?.map((item, index) => (
                <Grid key={`ck-${index}`} container spacing={2} columns={is_readonly ? 10 : 11}
                sx={{ mb: index === case_data?.hospital?.length - 1 ? 0 : 1 }}>
                <Grid item md={5}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={hospitals.map(({ id, name }) => ({ label: name, value: id }))}
                        fullWidth

                        value={item?.hospital}
                        onChange={(e, val) => onChangeInput(index, 'hospital', val)}
                        size="small"
                        renderInput={(params) => <TextField size="small" fullWidth {...params} placeholder="Select a hospital" />}
                    />
                </Grid>
                <Grid item md={5}>

                    <TextField size="small" fullWidth placeholder="MRN/NHI ID number" value={item?.nhi} onChange={(e) => 
                        onChangeInput(index, 'nhi', e.target.value)
                    } />
                </Grid>
                <CaseAction>
                <Grid item md={1}>
                    <IconButton size="small" onClick={() => dispatch(setCaseValue({
                        name: "hospital", value: case_data?.hospital?.filter((item, i) => i !== index)
                    }))}>
                        <DeleteIcon />
                    </IconButton>
        
                </Grid>
                </CaseAction>
            </Grid>
            ))}

            <CaseAction>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" size="small" onClick={() => dispatch(setCaseValue({
                    name: "hospital", value: [...case_data?.hospital, {
                        id: null,
                        hospital: null,
                        nhi: null,
                    }]
                }))}>Add Hospital</Button>
            </Box>
            </CaseAction>

        </Box>

    )
}





function NewHospital() {
    const [open, setOpen] = useState(false)
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const [apiReq, setApiReq] = useState({ loading: false, error: null })


    const dispatch = useDispatch()
    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })
        data.classification = data.classification === '' ? null : data.classification
        instance.post("/hospitals", data).then(res => {
            dispatch(fetchHospitals())
            setApiReq({ loading: false, error: null })
            reset()
            setOpen(false)

        }).catch(err => {
            let errorMessage = "Error adding new hospital";

            if (err.response && err.response.status === 409) {
                errorMessage = err.response.data || "Conflict error"; // Assuming the error message is in the `message` property of the response data
            }

            setApiReq({ loading: false, error: errorMessage });
        })
    }

    const onError = (error) => {

    }

    return (
        <>
            <Typography onClick={() => setOpen(true)} color="primary" sx={{
                fontSize: '13px', display: 'inline', "&:hover": {
                    color: "#000", textDecoration: 'underline', cursor: 'pointer'
                }
            }}>New</Typography>
            <GeneralDialog open={open} handleClose={handleClose} title="Add Hospital" disabled={apiReq.loading}
                onFormSubmit={handleSubmit(onSubmit, onError)}
                actions={
                    <Button disabled={apiReq.loading} type='submit' variant='contained' autoFocus>
                        {
                            apiReq.loading ? `SAVING` : `SAVE`
                        }
                    </Button>
                }
            >

               
                        <Box>
                            <Box>
                                <Grid container spacing={2} columns={6}>
                                    <Grid item md={6}>
                                        <CustomInputLabel>Please enter the new hospital name:</CustomInputLabel>
                                        <TextField size="small" fullWidth {...register('name', { required: true })} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <CustomInputLabel>Please select the hospital type:</CustomInputLabel>
                                        <MUIAutocomplete options={[
                                            { label: "PRIVATE", value: "PRIVATE" },
                                            { label: "CHILDREN", value: "CHILDREN" },
                                        ]} name="classification" control={control} errors={errors} required />
                                    </Grid>

                                </Grid>
                                {apiReq.error &&
                                    <Box>
                                        <Typography color={"error"}>{apiReq.error}</Typography>
                                    </Box>
                                }
                            </Box>
                        </Box>

            </GeneralDialog>
        </>
    )
}