import { Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';

import RolesTable from "./RolesTable";
import AddRole from "./AddRole";
import LoopIcon from '@mui/icons-material/Loop';
import { fetchRoles } from "../../../redux/roles";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import BreadCrumbs from "../../../components/BreadCrumbs";
import BContainer from "../../../components/BContainer";
import { useEffect, useState } from "react";
import MuiDataGrid from "../../../components/tables/MUIDataGrid";
import { COLUMNS } from "./utilities";


export default function AdminRoles() {
    const dispatch = useDispatch()
    const usersRoles = useSelector(state => state.roles)
    const [deleteReq, setDeleteReq] = useState({
        open: false, id: null, deleting: false
    })
  
    useEffect(() => {
        dispatch(fetchRoles())
    }, [])   

    return (
        <AppLayout title="Admin - Roles" px={0} apppadding={"0px"}>
            <BContainer>

                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Roles" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >
                    <Tooltip title="Refresh roles">
                        <IconButton size="small" onClick={() => dispatch(fetchRoles())}>
                            <LoopIcon />
                        </IconButton>
                    </Tooltip>
                    <Box sx={{ pt: 0.4, px: 1 }}>
                        <AddRole />
                    </Box>
                </Box>
            </BContainer>
            <Box>
                        <MuiDataGrid data={usersRoles?.data} loading={usersRoles.loading} columns={COLUMNS}
                        />
                            </Box>

        </AppLayout>
    )
}