import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import instance from '../../../../auth/utils/useJwt';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { DialogContentText } from '@mui/material';

export default function CreateInvoiceNoteDialog(props) {
    const [open, setOpen] = useState(false);
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        data.invoice_id = props.invoice_id;
        setApiReq({ loading: true, error: null });
        instance.post('/xero/invoice-notes', data).then(res => {
            setOpen(false);
            setApiReq({ loading: false, error: null });
            reset();
            props.onNoteCreated();
        }).catch(err => {
            console.error("Error creating invoice note");
            setApiReq({ loading: false, error: "Error creating invoice note" });
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button variant="contained" onClick={() => setOpen(true)} size="small">
                Add Note
            </Button>

            <Dialog
                open={open}
                fullWidth={true}
                aria-labelledby="create-invoice-note-title"
                aria-describedby="create-invoice-note-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="create-invoice-note-title">
                        {"Create Invoice Note"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ my: 1 }}>
                            Enter your note for the invoice below.
                        </DialogContentText>
                        <TextField
                            fullWidth
                            size="small"
                            multiline
                            rows={6}
                            {...register('note', { required: true })}
                            name="note"
                            error={Boolean(errors.note)}
                        />
                    </DialogContent>
                    <DialogActions sx={{ pb: 3, pr: 3 }}>
                        <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={apiReq.loading} variant="contained">
                            {apiReq.loading ? 'ADDING...' : 'ADD NOTE'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}
