import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import './App.css';
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
export default function TextEditor({
    value, onChange, editorRef
}) {
  

  const handleEditorChange = (e) => {
    onChange({
        target: {
            name: 'content',
            value: e
        }
    }
    )
    }
  return (
    <>
      <Editor
        apiKey='ygnn0k6xo90dxs8tgyr06nxpz7v6x7xvp8inyzb00sih0gs3'
        onInit={(_evt, editor) => editorRef.current = editor}
        initialValue={value}
        // value={value}
        init={{
          height: 400,
        //   menubar: false,
        //   plugins: [
        //     'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        //     'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        //     'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        //   ],
        //   toolbar: 'undo redo | blocks | ' +
        //     'bold italic forecolor | alignleft aligncenter ' +
        //     'alignright alignjustify | bullist numlist outdent indent | ' +
        //     'removeformat | help',
        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        //   height: 300,
        skin: false,
        content_css: false,
        content_style: `
        body { font-family: sans-serif; }
        code { background-color: rgb(233, 235, 239); color: #031b4e; padding: 2px 4px; border-radius: 4px; }
        
        `,
        plugins: 'link image code lists table media',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | table | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        }}
        // onEditorChange={handleEditorChange}
      />
    </>
  );
}