import React, { useState } from 'react';
import { Button } from '@mui/material';

import instance from '../../../../auth/utils/useJwt';
import DeleteDialog from '../../../../components/Dialogs/DeleteDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useSnackbar from '../../../../components/Dialogs/SnackBar';


export default function DeleteGuardianButton(props) {
    const { row, get_guardians } = props
    const [deleteAc, setDeleteAc] = useState({
        id: null, loading: false, open: false
    })
    const [SnackbarComponent, showSnackbar] = useSnackbar();

    const onDeleteAction = (ac) => {
        // On Delete action
        if (!ac) {
            setDeleteAc({ id: null, open: false, loading: false })
            return
        }
        setDeleteAc({ ...deleteAc, loading: true })

        instance.delete(`/guardian?patient_id=${props.id}&id=${deleteAc.id}`).then(res => {
            console.log(res)
            setDeleteAc({ ...deleteAc, loading: false, id: null, open: false })
            showSnackbar("Record deleted successfully", "success")
            get_guardians()
        }).catch(err => {
            console.log(err)
            setDeleteAc({ ...deleteAc, loading: false, id: null, open: false })
        })
    }

    return (
        <>
        <SnackbarComponent />
            <Button startIcon={<DeleteForeverIcon />} size='small' variant='contained' sx={{ backgroundColor: '#a90329', ml: 1 }} onClick={() => {
                setDeleteAc({ ...deleteAc, id: row.nextofkin_id, open: true })
            }}>Remove</Button>

            <DeleteDialog open={deleteAc.open}
            onClose={() => setDeleteAc({ ...deleteAc, open: false })}
            deleting={deleteAc.loading} onAction={onDeleteAction} />
        </>
    );
}
