import { Box, Button, Card, CardContent, Dialog, DialogContent, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import MUIAutocomplete from "../components/MUIAutocomplete";
import MUITextInput from "../../../../components/forms/MUITextInput";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from "../../../../components/Dialogs/CustomDialog";
import instance from "../../../../auth/utils/useJwt";
function PatientCaseInvoiceMetaData({
    setOpen, onSuccessfulSave, invoice_status, open
}) {

    const { control, handleSubmit, register, formState: { errors }, setValue, getValues, reset, watch } = useForm({
        defaultValues: {
            meta: {
                expense_type: ""
            }
        }
    });

    const { invoice_id, patient_id, case_id } = useParams()
    const reference = `${patient_id}-${case_id}`
    const [loading, setLoading] = useState(false)
    const expense_type = useWatch({ control, name: 'meta.expense_type' })

    const fetch_meta = () => {
        setLoading(true)
        instance.get(`/xero/invoice/payment-metadata?invoice_id=${invoice_id}`).then(res => {
            const data = res.data
            reset({
                meta: data
            })
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const onSubmit = (data) => {
        // data.invoice_id = invoice_id;
        // data.reference = reference;
        if (invoice_status === "PAID")
            return


        setLoading(true)
        const to_submit = {
            invoice_id: invoice_id,
            reference: reference,
            meta: data.meta
        }
        instance.post(`/xero/invoice/payment-metadata`, to_submit).then(res => {
            setLoading(false)
            onSuccessfulSave()
            setOpen(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetch_meta()
    }, [])

    return (
        <CustomDialog open={open} title="Invoice Payment Details" handleClose={() => setOpen(false)}
            actions={
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}
                        disabled={loading || invoice_status === "PAID"}
                    >Close</Button>
                    <Button variant="contained" type="submit" color="primary"
                        disabled={loading}
                    >Save</Button>
                </Box>
            }
            dialogContentSx={{
                minWidth: '500px',
            }}
            onFormSubmit={handleSubmit(onSubmit)} disabled={loading}
        >
            <Grid container spacing={2} columns={4}>
                <Grid item md={2} sm={4} xs={12}>
                    <CustomInputLabel>Expense Type</CustomInputLabel>
                    <MUIAutocomplete options={[
                        { label: "Expense Claim", value: "Expense Claim" },
                        { label: "Supplier EFT", value: "Supplier EFT" },
                        { label: "Supplier BPAY", value: "Supplier BPAY" },
                        { label: "International Transfer", value: "International Transfer" }
                    ]}
                        name="meta.expense_type"
                        control={control}
                        errors={errors}
                        required
                    />
                </Grid>
            </Grid>
            {/* Only if expense_type is eft */}
            {/* {expense_type === "Supplier EFT" && */}
            {["Expense Claim", "Supplier EFT"].includes(expense_type) &&
                <Grid container spacing={2} columns={4} sx={{ mt: 1 }}>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>BSB</CustomInputLabel>
                        <MUITextInput control={control} name="meta.bsb" placeholder="BSB" errors={errors}
                            required
                            fullWidth />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>Account Number</CustomInputLabel>
                        <MUITextInput control={control} name="meta.account_number" placeholder="Account number"
                            required
                            errors={errors} fullWidth />
                    </Grid>
                </Grid>
            }

            {expense_type === "Supplier BPAY" &&
                <Grid container spacing={2} columns={4} sx={{ mt: 1 }}>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>Biller</CustomInputLabel>
                        <MUITextInput control={control} name="meta.biller_code" placeholder="biller"
                            required
                            errors={errors} fullWidth />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>Reference</CustomInputLabel>
                        <MUITextInput control={control} name="meta.reference" placeholder="reference"
                            required
                            errors={errors} fullWidth />
                    </Grid>
                </Grid>
            }
            {/* iban, swift_bic */}
            {expense_type === "International Transfer" &&
                <Grid container spacing={2} columns={4} sx={{ mt: 1 }}>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>IBAN</CustomInputLabel>
                        <MUITextInput control={control} name="meta.iban" placeholder="IBAN"
                            required
                            errors={errors} fullWidth />
                    </Grid>
                    <Grid item md={2} sm={4} xs={12}>
                        <CustomInputLabel>Swift BIC</CustomInputLabel>
                        <MUITextInput control={control} name="meta.swift_bic" placeholder="Swift BIC"
                            required
                            errors={errors} fullWidth />
                    </Grid>
                </Grid>
            }
        </CustomDialog>
    )
}


export default function PatientCaseInvoiceMeta({
    onUpdate, invoice_status
}) {

    const [open, setOpen] = useState(false)
    return (
        <>
            <Box>
                <Button variant="contained" onClick={() => setOpen(true)} size="small">
                    {invoice_status === "PAID" ? "View" : "Add"} Payment Details
                </Button>
            </Box>

            <PatientCaseInvoiceMetaData open={open} setOpen={setOpen} onSuccessfulSave={onUpdate} invoice_status={invoice_status} />
        </>
    )
}