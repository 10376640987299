import { Box, IconButton, LinearProgress, Skeleton, Stack, Typography } from "@mui/material";
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { DataBox } from "./common";
import TableOfContent from "./TableOfContents";

export default function Sidebar({
    open, setOpen, title, totalTrainings, completedTrainings,
    trainings, loading,
    activeTrainingId, setActiveTrainingId
}) {

    return (
        <Box sx={{
            width: open ? "300px" : "0px",
            // Add visibility and overflow styles to hide content
            visibility: open ? 'visible' : 'hidden',
            overflow: 'hidden',
            opacity: open ? 1 : 0,
            transition: "width 0.5s, visibility 0.5s",
            borderRight: open ? "1px solid #ddd" : "none",
            height: "100vh",
        }}>

            {loading ?

                <Skeleton variant="rectangular" sx={{
                    height: "100%"
                }} />
                :
                <Stack direction={"column"} justifyContent={"space-between"} sx={{
                    height: "100%"
                }}>
                    <Box sx={{
                        px: 2
                    }}>

                        <Box>
                            <Stack direction={"row"} justifyContent={"flex-end"} sx={{ py: 1 }}>
                                <Box>
                                    <IconButton onClick={() => setOpen(false)}>
                                        <ArrowBackIos />
                                    </IconButton>
                                </Box>
                            </Stack>
                            <Stack direction={"column"} gap={2}>
                                <DataBox>
                                    <Typography variant="subtitle" sx={{ fontSize: "0.8rem", fontWeight: 500, py: 1 }}>
                                        {title}
                                    </Typography>

                                    <LinearProgress variant="determinate" value={
                                        (completedTrainings / totalTrainings) * 100
                                    } sx={{
                                        mt: 1, height: "8px"
                                    }} />
                                    <Typography variant="subtitle2" sx={{
                                        textAlign: 'right'
                                    }}>
                                        {`${completedTrainings}/${totalTrainings}`}
                                    </Typography>
                                </DataBox>

                                <TableOfContent content={trainings} activeTrainingId={activeTrainingId} setActiveTrainingId={setActiveTrainingId} />
                            </Stack>
                        </Box>


                    </Box>
                    <Box sx={{
                        p: 2
                    }}>
                        <Typography variant="h6" sx={{
                            textAlign: 'center', fontWeight: 600
                        }}>
                            ROMAC eHR
                        </Typography>
                    </Box>

                </Stack>
            }
        </Box>
    );
}
