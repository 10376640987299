import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CustomInputLabel from '../CustomInputLabel';
import { useForm } from 'react-hook-form';
import MUIAutocompleteMultipleCheck from '../MUIAutocompleteMultipleCheck';

export default function GeneralDropdownFilter({
    data, label, column, onFilter, ...props
}) {
    const [options, setOptions] = useState([])
    const filter_id = `filter-${column}`
    const { control, watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            [filter_id]: [
                { label: "All", value: "all" }
            ]
        }
    })
    const filter_val = watch(filter_id)
    
    useEffect(() => {
        console.log(data)
        // Get unique option values from data and ignore empty values
        const uniqueOptions = [...new Set(data?.map(item => item[column])?.filter(val => val))]
        const options = uniqueOptions.map(option => {
            return { label: option, value: option?.toLowerCase() }
        })
        setOptions([
            { label: "All", value: "all" },
            ...options
        ])
    }, [data])

    // Update parent component with filtered data
    useEffect(() => {
        if (filter_val === undefined) {
            return onFilter(data)
        }
        if (filter_val.length === 0) {
            return onFilter([])
        }
        // If "all" is selected, return all data
        const filterValues = filter_val.map(item => item.value)
        if (filterValues.includes("all")) {
            return onFilter(data)
        }

        // ignore "all"
        const filterValuesWithoutAll = filterValues.filter(item => item !== "all")
        
        const filteredData = data.filter(item => filterValuesWithoutAll.includes(item[column]?.toLowerCase()))
        return onFilter(filteredData)
    }, [filter_val])


    if (!options) {
        return <Box></Box>
    }

    return (
        <Box sx={{
            maxWidth: '300px',
            minWidth: '150px',
            ...props.sx
        }}>
            <CustomInputLabel sx={{
                fontWeight: 700
            }}>{
                label
            }</CustomInputLabel>
            <MUIAutocompleteMultipleCheck name={filter_id} errors={errors} control={control} options={options} />

        </Box>



    );
}
