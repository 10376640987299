import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import instance from "../../../../auth/utils/useJwt";
import Button from '@mui/material/Button'
import { useState } from 'react';
import { DialogContentText, Stack } from '@mui/material';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';


export default function ArchivePatient(props) {
    
    const { open, setOpen, patient_id, archived } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    
    const handleClose = () => {
        setOpen(false)
    }
    


    const onSubmit = () => {
        
        setApiReq({loading: true, error: null})
        instance.patch("/patients", {
            archived: props.archived ? false : true, 
            archiving: "yes",
            id: patient_id
        }).then(res => {
            props.showSnackbar(props.archived ? "Patient unarchived" : "Patient archived", "success")
            props.setOpen(false)
        setApiReq({loading: false, error: null})
        }).catch(err => {
            console.log("Error")
            //props.setOpen(false)
            props.showSnackbar("Error updating classification", "success")
            
        setApiReq({loading: false, error: "Error overriding classification"})
        })
    }


    return (

        <GeneralDialog open={open} handleClose={handleClose} title={props.archived ? "Unarchive Patient" : "Archive Patient"}
            disabled={apiReq.loading}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button onClick={onSubmit} disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `Processing` : `Confirm`
                        }
                    </Button>
                </Stack>
            }>
                    <DialogContentText>Are you sure you want to {props.archived ? "unarchive" : "archive"} this patient?</DialogContentText>
                    {apiReq.error && <DialogContentText sx={{ mt: 2, color: 'red', fontSize: '12px' }}>
                        Error performing action. Please try again later.
                    </DialogContentText>}
        </GeneralDialog>
    )
}