import Box from '@mui/material/Box';

import { useSelector, useDispatch } from "react-redux";
import CurrentForecast from "./components/CurrentForecast";
import OriginalEstimate from "./components/OriginalEstimate";
import ActualCosts from "./components/ActualCosts";
import CashFlow from "./components/CashFlow";

export default function Expenditure() {
    const { data, loading } = useSelector(state => state.patient_case)
    const dispatch = useDispatch

    
    
    if (!data) {
        return <Box></Box>
    }
    
    return (
        <Box>
            <OriginalEstimate />

            { /* Current Forecast */ }
            <CurrentForecast />

            <ActualCosts />

            <CashFlow />
        </Box>
    )
}