import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import { Box, TextField, IconButton, Typography, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import instance from '../../../../auth/utils/useJwt';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import DeleteMemo from './Dialogs/DeleteMemo';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import { MUIButton } from '../../../../components/forms/common/MUIButton';
import CaseAction from '../../../../components/PermissionsWrappers/CaseAction';

import TZDateView from '../../../../components/General/TZDateView';

const CTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '13px', color: "#333"
}))


export default function MemosLogs(props) {
    const [rows, setRows] = useState([])
    const [memosData, setMemosData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const { is_readonly } = useSelector(state => state.patient_case)
    const { patient_id, case_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
    const onAddMemo = () => {
        // setRows([...rows, {
        //     datetime: "9 0 click",
        //     who: 'faizanamin192@gmail.com',
        //     content: val
        // }])
        fetchMemos()
    }

    const fetchMemos = () => {
        setApiReq({ loading: true, error: null })
        instance.get(`/app-logs?patient_id=${props.patient_id}&case_id=${case_id}&endpoint=Memo,CaseStatus`).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            setMemosData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: null })
            console.log(err)
        })
    }

    useEffect(() => {
        fetchMemos()
    }, [])



    return (
        <Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
                        <TableRow>
                            <TableCell>Date / Time</TableCell>
                            <TableCell align="right">Method</TableCell>
                            <TableCell align="right">Action</TableCell>
                            <TableCell align="right">Who</TableCell>
                            <TableCell align="right">Old Value</TableCell>
                            <TableCell align="right">New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={4} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        }
                        {
                            memosData.length === 0 && !apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={4} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>There are no memos.</TableCell>
                            </TableRow>
                        }
                        {memosData.map((row, index) => (
                            <TableRow
                                key={`memo-${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <CTableCell sx={{ minWidth: '140px' }}>
                                    <TZDateView date={row.created_at}
                                        outputFormat="EEE, MMM dd yyyy hh:mm a"
                                    />
                                </CTableCell>
                                <CTableCell align="right" sx={{ minWidth: '120px' }}>
                                    {row.method}
                                </CTableCell>
                                <CTableCell align="right" sx={{ minWidth: '120px' }}>
                                    {row.endpoint}
                                </CTableCell>
                                <CTableCell align="right" sx={{ minWidth: '120px' }}>
                                    {row.username}

                                </CTableCell>
                                <CTableCell align="right" sx={{ py: 1.1, minWidth: '180px' }}>
                                    {row.old_value}
                                </CTableCell>
                                <CTableCell align="right" sx={{ width: '100%' }}>
                                    {row.new_value}
                                </CTableCell>

                               
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    );
}
