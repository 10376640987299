import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from "react-redux";
import { Divider, Button } from '@mui/material';
import { setCaseValue } from '../../../../../redux/case/case';
import { generateYearsList } from '../../../../../utils/generators';
import CustomInputLabel from '../../../../../components/forms/CustomInputLabel';

export default function CashFlow() {
    const { data, loading } = useSelector(state => state.patient_case)
    const dispatch = useDispatch()

    const deleteCashFlowAtIndex = (index) => {
        if (index >= 0 && index < data.cash_flow.length) {
            // Use filter to remove the item at the specified index
            const updatedEstimate = data.cash_flow.filter((_, i) => i !== index);

            dispatch(setCaseValue({
                name: 'cash_flow',
                value: updatedEstimate
            }));
        } else {
            console.error("Invalid index provided for deletion");
        }
    }

    const addNewCashFlow = () => {
        dispatch(setCaseValue({
            name: 'cash_flow', value: [
                ...data.cash_flow, { date: '', source: '', allocation: 0 }
            ]
        }))
    }

    const sum_val = () => {
        return data?.cash_flow.reduce((acc, item) => parseInt(acc) + parseInt(item["allocation"]), 0);
    }
    const sum_val_forecast = () => {
        return data?.current_forecast.reduce((acc, item) => parseInt(acc) + parseInt(item.medical) + parseInt(item.other), 0);
    }

    const onChangeText = (e) => {
        const { name, value, index } = e;

        // Create a new array with updated objects
        const oe = data.cash_flow.map((item, idx) => {
            if (idx !== index) { return item; }// Return original object if it's not the one we want to update
            return { ...item, [name]: value }// Only create a new object for the one we're updating
        });

        // Dispatch the updated array
        dispatch(setCaseValue({
            name: 'cash_flow',
            value: oe
        }));
    }


    if (!data) {
        return <Box></Box>
    }

    return (

        <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', gap: '10px', mb: 0.5 }}>
                <InputLabel sx={{ mt: 0.4 }}>Cash Flow</InputLabel>
                <Button size="small" sx={{ textTransform: 'capitalize' }} variant="contained" onClick={addNewCashFlow}>
                    Add
                </Button>
            </Box>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0.5 }}>

                <Grid item md={6}>
                    <Grid container spacing={2} columns={8}>
                        <Grid item md={2}>
                            <CustomInputLabel>Date</CustomInputLabel>

                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Source</CustomInputLabel>

                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Allocation</CustomInputLabel>

                        </Grid>
                    </Grid>
                    {data.cash_flow.map((item, index) => (
                        <Grid container spacing={2} columns={8} key={`cash-flow-${index}`} sx={{ mb: 0.3 }}>
                            <Grid item md={2}>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.date}
                                        onChange={(e) => onChangeText({
                                            name: 'date', value: e.target.value, index: index
                                        })}
                                        fullWidth
                                    >
                                        {
                                            generateYearsList().map((item, index) => (
                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                            ))

                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.source}
                                        onChange={(e) => onChangeText({
                                            name: 'source', value: e.target.value, index: index
                                        })}
                                        fullWidth
                                    >
                                        <MenuItem value={0}>AUD</MenuItem>
                                        <MenuItem value={1}>NZD</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                <TextField size="small" fullWidth value={item.allocation} onChange={(e) => onChangeText({
                                    name: 'allocation', value: e.target.value, index: index
                                })} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            </Grid>
                            <Grid item md={2}>
                                {index > 0 &&
                                    <IconButton onClick={() => deleteCashFlowAtIndex(index)}>
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid item md={6}>

                    <Grid container spacing={2} columns={8}>
                        <Grid item md={2}>
                            <CustomInputLabel>Total Allocation</CustomInputLabel>
                            <TextField disabled size="small" fullWidth value={sum_val()} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Forecast</CustomInputLabel>
                            <TextField disabled size="small" fullWidth value={sum_val_forecast()} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Balance</CustomInputLabel>
                            <TextField disabled size="small" fullWidth value={sum_val() - sum_val_forecast()} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>NZ$ Conversion</CustomInputLabel>
                            <TextField size="small" value={data.nzExchangeRate} onChange={(e) => dispatch(setCaseValue({
            name: 'nzExchangeRate',
            value: e.target.value
        }))} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}