import { Card, Grid, Box, Avatar, Typography, CircularProgress } from "@mui/material"

import { useSelector } from "react-redux"
import { useEffect, useState } from "react"

const GENDERS = {
    0: 'Male', 1: 'Female'
}
export default function DetailsCard(props) {
    const { apiReq, userData } = props
    const [doctor_data, setDoctorData] = useState({
        name: 'Tim Jupe',
        country: 'Australia',
        type: 'paediatrist',
        details: [
            [
                
            ],
            [
                
            ],
            [
                
            ]
        ]
    })

    const user = useSelector(state => state.user)
    const auth = useSelector(state => state.authentication).userData
    
    useEffect(() => {
        console.log(userData)
        if (userData) {
            setDoctorData({
                name: `${userData.first_name} ${userData.last_name}`,
                country: userData.country,
                type: 'paediatrist',
                details: [
                    [
                        { title: 'Email', value: userData.email },
                        { title: 'Phone', value: userData.phone },
                       // { title: 'Gender', value: GENDERS[userData.gender] }
                    ],
                    [
                        { title: 'Role', value: userData.role?.name },
                        { title: 'Region', value: userData.region },
                       // { title: 'Language', value: userData.languages?.map(item => item.label).join(', ') }
                    ],
                    [
                        { title: 'Join Date', value: user.first_login_date },
                        { title: 'Status', value: 'Active' },
                    ]
                ]
            })
        }
    }, [userData])


    

    return (
        <Box sx={{ py: 3 }}>
            {
                apiReq.loading ?
                <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>

            :

            <Grid container spacing={1}>
                <Grid item md={3}>
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Avatar alt="Remy Sharp" src={auth.picture} sx={{ width: 82, height: 82 }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', px: 2 }}>
                            {/* <Typography variant="subtitle2" color={"primary"}>
                                #{ doctor_data.type }
                            </Typography> */}
                            <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", lineHeight: 1.2 }}>
                                { doctor_data.name }
                            </Typography>
                            <Typography variant="caption" sx={{ padding: 0, margin: 0 }} color={"primary"}>
                                { userData?.email }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={9}>
                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>DETAILS</Typography>
                    <Grid container spacing={2}>
                        {
                            doctor_data.details.map((group_arr, group_ind) => {
                                return (
                                    <Grid key={`patient-data-g-${group_ind}`} item md={4} sm={4}>
                                        {
                                            group_arr.map((det, det_index) => (
                                                <Box key={`patient-data-${group_ind}-${det_index}`} sx={{ display: 'flex' }}>
                                                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92, 0.8)", fontWeight: 400, fontSize: '13px', minWidth: '80px' }}>{det.title}:</Typography>
                                                    <Typography variant="subtitle1" sx={{ color: "rgba(75, 70, 92)", fontWeight: 400, fontSize: '13px' }}>{det.value}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
}
        </Box>
    )
}