import { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import dayjs from 'dayjs';
import FiltersForm from "./FiltersForm";
import instance from "../../../auth/utils/useJwt";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { DATE_FORMAT } from "../../../configs/basic";
import BContainer from "../../../components/BContainer";
import DataGridTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { styled } from '@mui/material/styles';
import InsuranceSummary from "./Summary";
const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
}));
const YearList = () => {
    const startYear = 2004;
    const currentYear = new Date().getFullYear() + 1;
    const years = [];

    for (let i = startYear; i < currentYear; i++) {
        years.push({ label: `${i} / ${String(i + 1).slice(2)}`, value: `${i} / ${String(i + 1).slice(2)}` });
    }
    return years.reverse()
}

export default function InsuranceReports() {
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [insuranceReportsData, setInsuranceReportsData] = useState(null)
    const [summaryData, setSummaryData] = useState({
        period: params.get('duration'),
        cases_in_aus_nz: 0,
        patients_arrived: 0,
        patients_departed: 0
    })
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const navigate = useNavigate()

    
    const [filters, setFilters] = useState({
        financial_year: YearList()[0],
        from: dayjs().subtract(12, 'month').format(DATE_FORMAT),
        to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
    })

    const onClear = () => {
        setFilters({
            financial_year: YearList()[0],
            from: dayjs().subtract(8, 'month').format(DATE_FORMAT),
            to: dayjs().format(DATE_FORMAT), status: null, region: null, district: null,
        })
    }
    

    console.log(params.get('duration')); // "top"


    const fetchReports = () => {

        if (params.get("duration") === undefined || params.get("duration") === null){
            navigate("/reports/insurance")
        }


        const finalFilters = { ...filters, duration: params.get('duration') }
        setApiReq({ loading: true, error: null })
        instance.post("/reports/insurance-details", finalFilters).then(res => {
            console.log(res.data)
            // add index to id
            res.data?.data.forEach((item, index) => {
                item.id = `${item.id}-${index}`
            })
            setSummaryData({
                ...res.data.summary,
                period: params.get('duration')
            })
            setInsuranceReportsData(res.data?.data)
            setApiReq({ loading: false, error: null })

        }).catch(err => {

            setApiReq({ loading: false, error: "Error fetching records" })
        })
    }

 
    useEffect(() => {
        fetchReports()
    }, [])

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Insurance", url: "/reports/insurance" },
        { text: "Details (" + params.get('duration') + ")" },
    ]

    const tabOptions = [
        { label: "Summary", value: "summary" },
        { label: "Details", value: "details" }
    ]
    const [selectedTab, setSelectedTab] = useState("summary")

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                {/* <Card>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Filters</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <FiltersForm filters={filters} setFilters={setFilters} fetchData={fetchReports} onClear={onClear} loading={apiReq.loading} />
                    </Box>
                </Card> */}

                {/* <Box sx={{ textAlign: 'center', py: 2 }}>

                </Box> */}


                <Card>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoice tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {
                            tabOptions.map((tab, tabIndex) => {
                                return <StyledTab
                                style={{
                                    borderLeftWidth: tabIndex === 0 ? 1 : 0,
                                    backgroundColor: selectedTab !== tab.value ? "#F9F9F9" : undefined,
                                }}
                                label={`${tab.label}`} value={tab.value} key={tab.value} />
                            })
                        }
                    </Tabs>
                    { selectedTab === "summary" ? <InsuranceSummary
                    loading={apiReq.loading}
                    y data={
                        summaryData
                    } /> :
                    <DataGridTable data={insuranceReportsData} filters={filters} loading={apiReq.loading} />
}
                </Card>





            </Box>

        </AppLayout>
    )
}