import { Box, Button, Card, CardContent, CardHeader, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import instance from "../../../auth/utils/useJwt";

export default function FeedbackSubmission({ training_id }) {
    const [feedback, setFeedback] = useState("")
    const [previousFeedback, setPreviousFeedback] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const startTime = new Date().getTime()
    
    const submitFeedback = () => {
        instance.post(`/training/progress`, {
            feedback: feedback,
            time : (new Date().getTime() - startTime) / 1000,
            training_id: training_id
        }).then(res => {
            console.log(res)
        }).catch(err => {

        })
    }

    const fetchPreviousFeedback = () => {
        instance.get(`/training/progress?id=${training_id}`).then(res => {
            setPreviousFeedback(res.data)
            setFeedback(res.data.feedback)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (training_id) {
            fetchPreviousFeedback()
        }
    }, [training_id])
    

    return (
        <Card sx={{
            mb: 2
        }}>
            {/* <Typography variant="h6">Table of Content</Typography> */}
            <Box sx={{
                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
            }}>
                { previousFeedback ? "Feedback Submitted" : "Feedback Submission" }
            </Box>
            <CardContent>
                <TextField multiline fullWidth rows={3} placeholder="Enter your feedback"
                value={feedback}
                disabled={previousFeedback}
                onChange={(e) => setFeedback(e.target.value)} />

                {error && <Typography variant="caption" color="error">{error}</Typography>}
                { !previousFeedback &&
                <Button onClick={submitFeedback} disabled={isSubmitting} variant="contained" color="primary" sx={{ mt: 2 }}>
                    Mark as Completed
                </Button>
}
            </CardContent>
        </Card>
    );

}