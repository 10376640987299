import React, { useState, useEffect } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent, LinearProgress, Stack, Button } from "@mui/material";

import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import instance from "../../../auth/utils/useJwt";
import { Link } from "react-router-dom";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import CircularProgressFeedback from "../../../components/General/CircularProgressFeedback";


export default function Training() {

    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState({
        completed: 0,
        total: 0
    })

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get("/training?type=grouped").then(res => {
            setLoading(false)
            setTrainings(res.data?.trainings)
            setProgress({
                completed: res.data?.completed,
                total: res.data?.total
            })
            console.log(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])


    // Open a new window with half size in center of screen
    const openWindow = (url) => {
        const width = window.innerWidth / 1.4;
        const height = window.innerHeight / 1.2;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        window.open(url, "", `width=${width}, height=${height}, top=${top}, left=${left}`);
    }


    console.log(trainings)
    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training" }
                ]} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        {/* <Card> */}
                        {loading && <LinearProgress />}
                        <Box>

                            {
                                trainings.map((training, index) => (
                                    <Card key={index} sx={{ mb: 2 }}>
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Box>
                                                <Box style={{
                                                    width: "300px", height: "200px",
                                                    backgroundImage: `url(${training.folder.thumbnail})`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        opacity: 0.8
                                                    }
                                                }}></Box>
                                            </Box>
                                            <Box sx={{ px: 3 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography variant="h5">{training.folder.name}</Typography>
                                                    {training.completed === training.trainings?.length && <GridCheckCircleIcon style={{ color: "#06F", fontSize: "1.3rem" }} />}
                                                </Stack>
                                                <Typography variant="subtitle2" sx={{
                                                    color: "#666", fontSize: "0.8rem"
                                                }}>{`Total Estimated Time: ${training.trainings.reduce((acc, t) => acc + t.reading_minutes, 0)
                                                    } minutes`}

                                                </Typography>
                                                <Stack direction={"row"} gap={2} sx={{ mt: 2 }}>
                                                    <Box>
                                                        <Button variant="contained"
                                                            endIcon={<ArrowForwardIcon />}
                                                            sx={{
                                                                textTransform: 'capitalize'
                                                            }} size="small"
                                                            onClick={() => openWindow(`/training/${training.folder.slug}`)}
                                                            // component={Link} to={`/training/${training.folder.slug}`}
                                                            >
                                                            {training.completed === 0 ? "Start" : "Resume"}
                                                        </Button>
                                                    </Box>
                                                    <Stack direction={"row"} gap={1} sx={{ pt: 0.3 }}>
                                                        <CircularProgressFeedback variant="determinate"
                                                            size={25}
                                                            value={(training.completed / training.trainings?.length) * 100} />
                                                        {Math.round((training.completed / training.trainings?.length) * 100)}%
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Stack>

                                    </Card>
                                ))
                            }



                        </Box>

                        {/* </Card> */}
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <Box sx={{
                                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                            }}>Progress</Box>
                            <CardContent>
                                <Typography variant="subtitle2">You have completed {
                                    progress.completed
                                } out of {
                                        progress.total
                                    } trainings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    )
}