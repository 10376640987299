import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import { MenuItem, Select } from '@mui/material';
import { setAllCasesData } from '../../../redux/all_cases';

export default function ViewsFilter(props) {
    const { queryArg, loading, queryArgs } = useSelector(state => state.all_cases)
    const dispatch = useDispatch()

    return (
        <Box sx={{
            maxWidth: '250px',
            minWidth: '120px',
            ...props.sx
        }}>
            {/* <MUIAutocomplete name={"views"} errors={errors} control={control} options={} chips={false} /> */}
            <CustomInputLabel sx={{ fontWeight: 'bold' }}>
                Views
            </CustomInputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={queryArg}
                sx={{ width: '150px' }}
                disabled={loading}
                onChange={(e) => {
                    console.log(e.target.value)
                    dispatch(setAllCasesData({ key: 'queryArg', value: e.target.value }))
                }}
            >
                {
                    queryArgs.map((item, index) => {
                        return (
                            <MenuItem key={`query-arg-${index}`} value={item.value}>{item.label}</MenuItem>
                        )
                    })
                }
            </Select>

        </Box>



    );
}
