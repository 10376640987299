import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from '@mui/material/styles';
import './styles.css'
import MuiDataGrid from "../../../components/tables/MUIDataGrid";
import { INVOICE_COLUMNS } from "./invoice_columns";
import GeneralDropdownFilter from "../../../components/forms/filters/GeneralDropdownFilter";

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
}));

export default function InvoicesGrid({
    invoices, loading, selectedTab, handleTabChange, tabOptions
}) {
    const [filteredInvoices, setFilteredInvoices] = useState([]);

    useEffect(() => {
        setFilteredInvoices(invoices)
    }, [invoices])
    return (
 
               
                <MuiDataGrid data={filteredInvoices} loading={loading} columns={INVOICE_COLUMNS}
                filters = {
                    <GeneralDropdownFilter data={invoices} column="expense_type" 
                    label="Expense Type" onFilter={(data) => setFilteredInvoices(data)}
                    sx={{
                        minWidth: 300, maxWidth: 500
                    }}
                    />
                }
                searchColumns={
                    [
                        "Date", "InvoiceNumber", "Reference", "contact_name", "Status"
                    ]
                }
                TabsView={
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoice tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {
                            tabOptions.map((tab, tabIndex) => {
                                return <StyledTab
                                style={{
                                    borderLeftWidth: tabIndex === 0 ? 1 : 0,
                                    backgroundColor: selectedTab !== tab.value ? "#F9F9F9" : undefined,
                                }}
                                label={`${tab.label} (${tab.count?tab.count:0})`} value={tab.value} key={tab.value} />
                            })
                        }
                    </Tabs>
                }
                
                searchPlaceholder={"Search by Invoice/Case Number, Contact or Amount"}
                />
    )
}
