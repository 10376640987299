import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import Tooltip from '@mui/material/Tooltip';




export const INVOICE_COLUMNS = [
    { field: 'Date', headerName: 'Date', flex: 1 },
    {
        field: 'InvoiceNumber', headerName: 'Invoice Number', flex: 1, renderCell: (params) => {
            return <Link to={params.row.url}>{params.row.InvoiceNumber || 'Invoice'}</Link>
        }
    },
    { field: 'Reference', headerName: 'Case Number', flex: 1 },
    {
        field: 'contact_name', headerName: 'Contact Name', flex: 1, renderCell: (params) => {
            return params.row.contact_name
        },
        exportValue: (params) => params.row.contact_name
    },
    {
        field: "expense_type", headerName: "Expense Type", flex: 1, 
        renderCell: (params) => {
            const meta = params.row.meta || {};
            return (
                <Box>
                    <Tooltip 
                        title={
                            <React.Fragment>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Date Added</td>
                                            <td>{meta.created_at?.slice(4, 30)}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{meta.expense_type}</td>
                                        </tr>
                                        <tr>
                                            <td>BSB/BPAY Code/IBAN</td>
                                            <td>{meta.bsb || meta.biller || meta.iban || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Account No./Reference/Swift Code</td>
                                            <td>{meta.account_number || meta.reference || meta.swift_bic || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>User Email</td>
                                            <td>{meta.user}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </React.Fragment>
                        }
                    >
                        <span>{meta.expense_type || ''}</span>
                    </Tooltip>
                </Box>
            );
        },
        exportValue: (params) => params.row.expense_type
    },
    { field: "bsb_biller", headerName: "BSB/Biller", flex: 1, visible: false },
    { field: "acc_ref", headerName: "Acc/Ref No.", flex: 1, visible: false },
    
    
    { field: 'AmountDue', headerName: 'Amount Due', flex: 1, renderCell: (params) => {
        return params.row.AmountDue?.toFixed(2)
    }, exportValue: (params) => params.row.AmountDue.toFixed(2)
    },
    {
        field: "AmountPaid", headerName: "Amount Paid", flex: 1, renderCell: (params) => {
            return params.row.AmountPaid?.toFixed(2)
        }, exportValue: (params) => params.row.AmountPaid.toFixed(2)
    },
    { field: 'Status', headerName: 'Status', flex: 1 },
    { field: 'TotalTax', headerName: 'Total Tax', flex: 1, renderCell: (params) => {
        return params.row.TotalTax?.toFixed(2)
    }},
    { field: 'CurrencyCode', headerName: 'Currency', flex: 1 },
    { field: 'HasAttachments', headerName: 'Files', width: 65, renderCell: (params) => {
        return params.row.HasAttachments ? 
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.4, color: "#077BC5" }}>
            
        { params.row.attachment_count } 
            <InsertDriveFileOutlinedIcon sx={{color: "#077BC5",
            fontSize: "1.2rem",
            cursor: "pointer"
        }} />
        </Box>
        : null
    }},
    
];
