import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export default function IconToolTip({text}) {
  return (
    <Tooltip title={text}>
        <InfoOutlinedIcon sx={{ fontSize: 16, mt: 0.2, ml: 0.2, color: "#09f", cursor: "pointer",
        "&:hover": {
            color: "#03f"
        }}} />
    </Tooltip>
  );
}