import { MenuItem, Select, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

import { styled } from '@mui/system';
import { useEffect, useState } from "react";
import useSnackbar from "../../../../components/Dialogs/SnackBar";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function Preferences() {
    const [defaultPage, setDefaultPage] = useState('/');

    const pageOptions = [
        { label: 'Dashboard', value: '/' },
        { label: 'Patients', value: '/patients' },
        { label: 'Cases', value: '/cases' },
    ]

    
    const [SnackbarComponent, showSnackbar] = useSnackbar();

    useEffect(() => {
        // Get default page from local storage
        const defaultPage = localStorage.getItem('romacDefaultPage', "/") || "/";
        setDefaultPage(defaultPage);
    }, [])

    const handlePageChange = (event) => {
        setDefaultPage(event.target.value);
        localStorage.setItem('romacDefaultPage', event.target.value);
        showSnackbar('Default page updated successfully', 'success');
    }

    return (
        <Box sx={{ pt: 1 }}>
            <SnackbarComponent />
            <Box>
                <Typography>Preferences</Typography>
                <Typography sx={{
                    fontSize: '13px',
                    py: 1.4,

                }}>
                    Which page you want to show first after you log in?
                </Typography>

                <Box sx={{  gap: 2, alignItems: 'center' }}>
                    <CustomInputLabel>Select Default Page</CustomInputLabel>
                    <Box>
                    <Select value={defaultPage} onChange={handlePageChange} sx={{ width: '200px' }} size="small" variant="outlined">
                        {pageOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                    </Box>
                </Box>

            </Box>
           
        </Box>
    )
}