import Chip from "@mui/material/Chip";

const colors = {
    'referral': { background: 'rgba(168, 170, 174, 1)', text: 'rgba(50, 50, 50, 1)' },
    'pending': { background: 'rgba(255, 159, 67, 1)', text: 'rgba(100, 50, 0, 1)' },
    'accepted': { background: '#a6d3bb', text: '#002e15' },
    'rejected': { background: 'rgb(194, 96, 79)', text: 'rgba(50, 0, 0, 1)' },
    'assisted': { background: 'rgba(123, 104, 238, 1)', text: 'rgba(30, 30, 60, 1)' },
    'by_others': { background: 'rgba(255, 105, 180, 1)', text: 'rgba(100, 0, 60, 1)' },
    'completed': { background: 'rgba(75, 192, 192, 1)', text: 'rgba(0, 80, 80, 1)' },
    'current': { background: 'rgba(54, 162, 235, 1)', text: 'rgba(0, 50, 100, 1)' },
    'deceased': { background: 'rgba(139, 0, 0, 1)', text: 'rgba(50, 0, 0, 1)' },
    'facilitated': { background: 'rgba(255, 206, 86, 1)', text: 'rgba(100, 80, 0, 1)' },
    'invalid_ops': { background: 'rgba(128, 128, 128, 1)', text: 'rgba(50, 50, 50, 1)' },
    'missing': { background: 'rgba(220, 53, 69, 1)', text: 'rgba(100, 0, 0, 1)' },
    'monitor': { background: 'rgba(102, 205, 170, 1)', text: 'rgba(0, 80, 60, 1)' },
    'referred': { background: 'rgba(255, 99, 132, 1)', text: 'rgba(100, 0, 30, 1)' }
}


export default function CaseStatus({
    text
}) {
    
    return (
        <Chip label={text}
                size='small'
                style={{ backgroundColor: colors[text?.toLowerCase()]?.background, color: colors[text.toLowerCase()]?.text }} />
    )
}