// ** React Imports
import { Navigate, Outlet } from "react-router-dom"
import { isUserLoggedIn } from "../auth/utils" 
import { useSelector } from "react-redux"
import { CanViewExpenses } from "../redux/user_roles"

const ExpensesRoute = (props) => {
const CanViewExpensesPermission = useSelector(CanViewExpenses)
  const userLoggedIn = isUserLoggedIn()
  if (userLoggedIn) {
    // if (user.role?.type !== "A" || user.role.type !== "B"){
    if (!CanViewExpensesPermission)
        return <Navigate to={"/"} />
    
    return <Outlet />
  }else{
    return <Navigate to={"/login"} />
  }
}


export default ExpensesRoute