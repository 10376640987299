import * as React from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Stack } from '@mui/material';
import GeneralDialog from './CustomDialog';

export default function DeleteDialog(props) {

    const { open, deleting, onClose, onAction } = props
    const handleClose = () => {
        onClose && onClose(false)
        props.onAction(false)
    }

    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Delete Record" closeOutsideClick={false}
            disabled={deleting}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} variant="outlined" color="primary"
                        disabled={deleting}
                    >Cancel</Button>
                    <Button variant="contained" color="error"
                        disabled={deleting}
                        onClick={() => onAction(true)}
                    >Delete</Button>
                </Stack>
            }
        >
            <DialogContentText>
                Are you sure you want to delete this record?
            </DialogContentText>
        </GeneralDialog>
    );
}
