import React from 'react';
import { Skeleton, Box, Grid, Typography } from '@mui/material';

const LoadingSkeleton = () => {
    return (
        <Box sx={{ py: 3 }}>
            {/* Acceptance Section */}
            <Grid container spacing={2}>
                {[...Array(6)].map((_, index) => (
                    <Grid item xs={1} sm={1} key={`skeleton-${index}`}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                ))}

            </Grid>

            <Box mb={3}>
                <Typography variant="h6">
                    <Skeleton width="30%" />
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                </Grid>
            </Box>

            {/* Approval Section */}
            <Box mb={3}>
                <Typography variant="h6">
                    <Skeleton width="30%" />
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                </Grid>
            </Box>

            {/* Region Section */}
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default LoadingSkeleton;
