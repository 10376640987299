import { Box } from "@mui/material";
import AdminUserPermissions from "../../components/Permissions";

export default function Permissions({
    id
}) {
    return (
        <Box sx={{ px: 2 }}>
            <AdminUserPermissions user_id={id} />
        </Box>
    )
}