import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
export default function HelpDocs() {
    const navigate = useNavigate()
    return (
        <><Tooltip title="Help/Docs">
            <IconButton 
            onClick={() => navigate("/docx")}
                size="large"
                aria-label="Help/Docs"
                color="inherit"

                id="notification-button"
            >
                    <HelpOutlineOutlinedIcon sx={{ color: "#555" }} />
            </IconButton>
        </Tooltip>

           
        </>
    );
}