import instance from "../../../../auth/utils/useJwt";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicalClassifications } from '../../../../redux/medical/classification';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import { Stack } from '@mui/material';


export default function OverrideClassification(props) {
    const { open, setOpen, patientData } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const { case_id } = useParams()
    const { reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const handleClose = () => {
        setOpen(false)
    }
    const dispatch = useDispatch()

    useEffect(() => {
        reset({
            "medical_classification": patientData?.case_classification,
            "referral_date": patientData.referral_date
        })
    }, [patientData])

    const classifications = useSelector(state => state.classifications).data
    useEffect(() => {

        if (classifications.length === 0) {
            dispatch(fetchMedicalClassifications())
        }

    }, [])

    const onSubmit = (data) => {
        data.patient_id = patientData.id
        data.case_id = parseInt(case_id)
        setApiReq({ loading: true, error: null })
        instance.post("/case/override-classification", data).then(res => {
            console.log("Done")
            props.showSnackbar("Updated", "success")
            props.setOpen(false)
            setApiReq({ loading: false, error: null })
        }).catch(err => {
            console.log("Error")
            //props.setOpen(false)
            props.showSnackbar("Error updating classification", "success")

            setApiReq({ loading: false, error: "Error overriding classification" })
        })
    }

    const onError = (error) => {

    }

    function formatDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Classification Override"
            disabled={apiReq.loading} onFormSubmit={handleSubmit(onSubmit, onError)}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `SAVING` : `SAVE`
                        }
                    </Button>
                </Stack>
            }>

            <Grid container spacing={2} columns={12}>
                <Grid item md={6}>
                    <CustomInputLabel>Classification</CustomInputLabel>
                    <FormControl fullWidth>
                        <Controller
                            name="medical_classification"
                            control={control}
                            defaultValue={null}
                            rules={{ required: 'Medical classification is required!' }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    options={classifications.map(({ id, name }) => ({ label: name, value: id }))}
                                    //getOptionLabel={(option) => option.title}
                                    value={value}
                                    onChange={(_, newValue) => onChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" variant="outlined" fullWidth
                                            error={Boolean(errors.medical_classification || !watch("medical_classification"))} />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                    <CustomInputLabel>Reassign the case classification.</CustomInputLabel>
                </Grid>
                <Grid item md={6}>
                    <CustomInputLabel>Referral Date</CustomInputLabel>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker style={{ padding: '4px' }} onChange={(e) => setValue("referral_date", formatDate(e.$d))} slotProps={{ textField: { size: 'small' } }}
                            defaultValue={dayjs(patientData.referral_date)}
                        />
                    </LocalizationProvider>

                    <CustomInputLabel>Adjust the referral date.</CustomInputLabel>
                </Grid>

            </Grid>


        </GeneralDialog>
    )
}