import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import instance from '../../../auth/utils/useJwt';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchDistricts } from '../../../redux/districts';
import { fetchRegions } from '../../../redux/region';
import { useSelector, useDispatch } from 'react-redux'
import { fetchRoles } from '../../../redux/roles';
import { setRolesData } from '../../../redux/roles';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../components/Dialogs/CustomDialog';
import { Stack } from '@mui/material';


export default function AddRole() {
    const [open, setOpen] = useState(false);
    const [userRoles, setUserRoles] = useState([])
    const editRow = useSelector(state => state.roles).editRow
    const [roleTypes, setRoleTypes] = useState([])
    const dispatch = useDispatch()
    const [processing, setProcessing] = useState(false)

    const regions = useSelector(state => state.regions).data
    const districts = useSelector(state => state.districts).data
    //console.log(roleTypes)

    const [formData, setFormData] = useState({
        email: '', name: '', description: '', type: '', region: '', district: '', permissions: []
    })

    useEffect(() => {
        if (editRow) {

            const region = editRow.region ? regions.find(obj => obj.name === editRow.region) : null
            const district = editRow.district ? districts.find(obj => obj.name === editRow.district) : null
            setFormData({
                email: editRow.email, name: editRow.name, description: editRow.description, permissions: editRow.permissions,
                type: roleTypes.find(obj => obj.value === editRow.type),
                region: region ? { label: region.name, value: region.id } : '',
                district: district ? { label: district.name, value: district.id } : ''
            })
            setOpen(true)
        }
    }, [editRow])

    console.log(formData)

    const onChangeField = (e) => {
        const key = e.target.name
        const val = e.target.value

        if (key === 'permissions') {
            const value = typeof val === 'string' ? val.split(',') : val
            console.log(value)
            setFormData({
                ...formData,
                [e.target.name]: value
            })
        } else if (key === "type") {
            if (val?.value === "A") {
                setFormData({
                    ...formData,
                    [key]: val, region: null, district: null
                })
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]: val
                })
            }
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })

        }

    }

    const resetForm = () => {
        setFormData({ email: '', name: '', description: '', type: '', region: '', district: '', permissions: [] })
    }


    const onAddRole = () => {
        const rqData = {
            name: formData.name, description: formData.description, email: formData.email,
            permissions: formData.permissions, type: formData.type?.value, region: formData.region?.value,
            district: formData.district?.value
        }

        setProcessing(true)
        if (editRow) {
            rqData['id'] = editRow.id
            instance.patch("/admin/roles", rqData).then(res => {
                setProcessing(false)
                setOpen(false)
                dispatch(setRolesData({
                    key: 'editRow', value: null
                }))
                resetForm()

                dispatch(fetchRoles())
            }).catch(err => {
                console.log(err)
                setProcessing(false)
            })

            return
        }




        instance.post("/admin/roles", rqData).then(res => {
            console.log(res)
            setProcessing(false)
            setOpen(false)
            dispatch(fetchRoles())
            resetForm()
        }).catch(err => {
            console.log(err)
            setProcessing(false)
        })
    }

    const getUserRoles = () => {
        instance.get("/admin/users/roles").then(res => {
            var roles = []
            res.data.forEach(element => {
                roles.push({ label: element.name, value: element.id })
            });
            setUserRoles(roles)
        }).catch(err => {
            console.log(err)
        })
    }

    const getUserRolesTypes = () => {
        instance.get("/admin/role-types").then(res => {
            var roles = []
            res.data.forEach(element => {
                roles.push({ label: element.name, value: element.id })
            });
            setRoleTypes(roles)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (userRoles.length === 0) {
            getUserRoles()
        }
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
        if (districts.length === 0) {
            dispatch(fetchDistricts())
        }
        getUserRolesTypes()
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(setRolesData({
            key: 'editRow', value: null
        }))
        resetForm()
    };

    return (
        <div>
            <Button variant="contained" size="small" onClick={handleClickOpen}>
                Add Role
            </Button>
            <GeneralDialog open={open} handleClose={handleClose} title={editRow ? 'Edit Role' : 'Add New Role'}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button disabled={processing} onClick={handleClose} variant='outlined' color="error">Cancel</Button>
                        <Button disabled={processing} onClick={onAddRole} variant="contained" autoFocus>
                            {editRow ? 'Update' : 'Add'}
                        </Button>
                    </Stack>
                }
            >

                <Box>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Box>
                                <CustomInputLabel>Name</CustomInputLabel>
                                <TextField size="small" fullWidth name='name' value={formData.name}
                                placeholder='Enter role name'
                                onChange={onChangeField} />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box>
                                <CustomInputLabel>Email</CustomInputLabel>
                                <TextField size="small" fullWidth name='email' value={formData.email}
                                placeholder='Enter role email'
                                onChange={onChangeField} />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box>
                                <CustomInputLabel>Description</CustomInputLabel>
                                <TextField size="small" fullWidth name='description'
                                placeholder='Enter role description'
                                value={formData.description} onChange={onChangeField} />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box >
                                <CustomInputLabel>Type</CustomInputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={roleTypes}
                                    size="small"
                                    value={formData.type}
                                    onChange={(event, newValue) => onChangeField({
                                        target: {
                                            name: "type", value: newValue
                                        }
                                    })}
                                    renderInput={(params) => <TextField {...params} fullWidth placeholder="Select a role type" />}
                                />

                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box >
                                <CustomInputLabel>Region</CustomInputLabel>
                                <Autocomplete
                                    disablePortal

                                    id="combo-box-demo"
                                    options={regions.map(({ id, name }) => ({ label: name, value: id }))}
                                    size="small"
                                    disabled={formData.type?.value !== "RC"}
                                    value={formData.region}
                                    onChange={(event, newValue) => onChangeField({
                                        target: {
                                            name: "region", value: newValue
                                        }
                                    })}
                                    renderInput={(params) => <TextField {...params} fullWidth placeholder="Select a region" />}
                                />
                            </Box>

                        </Grid>
                        <Grid item md={6}>
                            <Box >
                                <CustomInputLabel>District</CustomInputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={districts.map(({ id, name }) => ({ label: name, value: id }))}
                                    size="small"
                                    value={formData.district}
                                    disabled={formData.type?.value !== "DC"}
                                    onChange={(event, newValue) => onChangeField({
                                        target: {
                                            name: "district", value: newValue
                                        }
                                    })}
                                    renderInput={(params) => <TextField {...params} fullWidth placeholder="Select a district" />}
                                />

                            </Box>

                        </Grid>
                        <Grid item md={12}>
                            <CustomInputLabel>Permissions</CustomInputLabel>
                            <FormControl fullWidth>
                                <Select
                                    multiple
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.permissions}
                                    name="permissions"
                                    onChange={onChangeField}
                                    placeholder="Select Permissions"
                                >
                                    {
                                        Object.entries({ "EDIT": "Edit Patient Records", "VIEW": "View Patient Records" }).map(([key, value]) => (
                                            // Perform some transformation or operation on key and value

                                            <MenuItem value={key}>{value}</MenuItem>
                                        ))

                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </GeneralDialog>
        </div>
    );
}
