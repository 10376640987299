import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import { Card, CardContent, Box, CircularProgress, CardHeader, Table, TableBody, TableRow, TableCell } from "@mui/material";
import instance from "../../../auth/utils/useJwt";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function DonationsDetailsPage() {
    const [loading, setLoading] = useState(true)
    const [donationData, setDonationData] = useState({})
    const [breadcrumbs_v, setBreadcrumbs_v] = useState([
        { text: "Donations", url: "/donations" }
    ])
    const { id } = useParams()
    const fetchDonations = () => {
        setLoading(true)
        instance.get(`/donations?id=${id}`).then(res => {
           
            setDonationData(res.data)
            setBreadcrumbs_v([
                { text: "Donations", url: "/donations" },
                { text: "Details", url: `/donations/${res.data.id}` }
            ])
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchDonations()
    }, [])

    
    const user = useSelector(state => state.user)

    const get_name = (row) => {
        if (row.first_name) {
            return `${row.first_name} ${row.last_name}`
        }else if (row.organization) {
            return row.organization
        }else if (row.club) {
            return row.club
        }
    }



    return (
        <AppLayout title="Donations" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <BreadCrumbs data={breadcrumbs_v} />
                </Box>
            </BContainer>
            <Box sx={{ px: 3 }}>
                { user.donations ?
            <Card sx={{ margin: '0 auto', mt: 3 }}>
                {/* <CardHeader title="Maps" sx={{ borderBottom: '1px solid #ddd' }} /> */}
                <CardHeader title="Donation Details" sx={{ borderBottom: '1px solid #ddd' }} />
                <CardContent sx={{ px: 0, py: 0 }}>
                { loading ? <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}><CircularProgress /></Box> :
                <Box>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Donor</TableCell>
                                <TableCell>{get_name(donationData)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Amount</TableCell>
                                <TableCell>{donationData.amount}</TableCell>
                            </TableRow>
                            { donationData.phone &&
                            <TableRow>
                                <TableCell>Phone</TableCell>
                                <TableCell>{donationData.phone}</TableCell>
                            </TableRow>
                            }
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>{donationData.email}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>    
}
                </CardContent>
            </Card>
            :
            <Card sx={{ margin: '0 auto', mt: 3 }}>
                <CardContent sx={{ px: 0, py: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                        You have no permission to view this page
                    </Box>
                </CardContent>
            </Card>
                }
            </Box>
        </AppLayout>
    )
}