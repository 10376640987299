import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, CircularProgress, Grid } from '@mui/material';
import instance from '../../../auth/utils/useJwt';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileUploadIcon from '@mui/icons-material/FileUpload';
function CustomFileSelect({ avatar_type, onSuccess, drive_id, patient_id }) {
    const [loading, setLoading] = useState(false)
    // Handle file upload
    const handleChangeFile = (e) => {
        if (e.target.files.length === 0) {
            return
        }
        setLoading(true)

        const file = e.target.files[0]
        // api/v1/drive/upload
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'Photos')
        formData.append('drive_id', drive_id)
        // add patient id
        formData.append('patient_id', patient_id)
        instance.post('/drive/upload', formData).then(res => {
            console.log(res)
            //setPhotoUrls([...photoUrls, res.data])
            onSuccess(res.data, avatar_type)
            setLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {/* File input */}
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id={`contained-button-file-${avatar_type}`}
                type="file"
                onChange={handleChangeFile}
            />
            <label htmlFor={`contained-button-file-${avatar_type}`}>
                <Button variant="contained" component="span" size="small" disabled={loading} startIcon={<FileUploadIcon />}>
                    {loading ? 'Uploading...' : 'Upload File'}
                </Button>
            </label>
        </Box>
    )


}


export default function EditAvatar(props) {
    const { data, drive_id, patient_id } = props
    const [open, setOpen] = useState(false);
    const [driveLoading, setDriveLoading] = useState(false)
    const [apiReq, setApiReq] = useState({
        loading: false,
        success: false,
        error: false
    })
    const [avatars, setAvatars] = useState({
        updating_avatar: true,
        avatar_before: null,
        avatar_after: null
    })
    useEffect(() => {
        setAvatars({
            id: patient_id,
            updating_avatar: true,
            avatar_before: data?.avatar_before,
            avatar_after: data?.avatar_after
        })
    }, [data])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [photoUrls, setPhotoUrls] = useState([])

    useEffect(() => {
        setDriveLoading(true)
        instance.get(`/drive/files?id=${data?.drive_id}&folder=Photos`).then(res => {
            console.log(res)
            setPhotoUrls(res.data)
            setDriveLoading(false)
        }).catch(err => {
            console.log(err)
            setDriveLoading(false)
        })
    }, [data?.drive_id])

    const onSave = () => {
        setApiReq({ ...apiReq, loading: true, success: false, error: false })
        instance.patch(`/patients`, avatars).then(res => {
            console.log(res)
            setApiReq({ ...apiReq, loading: false, success: true, error: false })
            handleClose()
        }).catch(err => {
            setApiReq({ ...apiReq, loading: false, success: false, error: true })
        })
    }


    const handleChange = (val) => {
        console.log(val)
        setAvatars({ ...avatars, 'avatar_before': val.thumbnailLink })
        props.onChangeVal(val)
    }

    const onSuccess = (data, avatar_type) => {
        handleChange(data)
        setPhotoUrls([...photoUrls, data])
    }

    return (
        <>
            <Typography variant="body" color={"primary"} onClick={handleClickOpen} sx={{ fontSize: '13px', cursor: 'pointer' }}>Edit Avatar</Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    borderBottom: '1px solid #ccc',
                    display: 'flex', justifyContent: 'space-between',
                }}>
                    <div>Change "{data?.first_name}" avatars</div>

                    <CustomFileSelect avatar_type="avatar_before" onSuccess={onSuccess} drive_id={drive_id} patient_id={patient_id} />
                </DialogTitle>

                <DialogContent sx={{ minWidth: '650px', py: 3 }}>
                    {
                        driveLoading ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                                <CircularProgress color="primary" />
                            </Box> : null
                    }
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {photoUrls.map((photo, index) => (
                            <Grid item key={index}>
                                <Avatar alt={photo.name} src={photo.thumbnailLink}
                                    onClick={() => handleChange(photo)}
                                    sx={{
                                        width: 100, height: 100, cursor: 'pointer',
                                        border: avatars.avatar_before === photo.thumbnailLink ? '5px solid red' : 'none'
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
                <DialogActions sx={{ borderTop: '1px solid #ccc' }}>
                    <Button onClick={handleClose} color="error"
                        disabled={apiReq.loading}
                    >Cancel</Button>
                    <Button
                        disabled={apiReq.loading}
                        onClick={
                            onSave
                        } color="primary" autoFocus>
                        {apiReq.loading ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
