import * as React from 'react';
import StarBorder from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import Star from '@mui/icons-material/Star';
import instance from '../../../auth/utils/useJwt';
import { useDispatch } from 'react-redux';
import { togglePatientCaseStarred } from '../../../redux/all_cases';
export default function StarPatientCase(props) {
    const { patient_id, case_id, romac_case_id, starred } = props

    const dispatch = useDispatch()

    const toggleStar = () => {
            instance.get(`/case/star-unstar?patient_id=${patient_id}&case_id=${case_id}`).then(res => {
                dispatch(togglePatientCaseStarred({
                    id: romac_case_id, starred: starred ? false : true
                }))
            }
            ).catch(err => {
                console.log(err)
            })
    }


    return (
        <IconButton
            sx={{ pl: 0, ml: 0 }}
            aria-label="Star patient"
            onClick={toggleStar} // You need to implement this method
        >
            {starred ? <Star sx={{ color: "gold" }} /> : <StarBorder />}
        </IconButton>
    );
}