import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import PatientData from "./PatientData"
import { useState } from "react"
import Background from "./Background"
import Medical from "./Medical"
import Referrer from "./Referrer"
import Guardians from "./Guardians"



export default function ReferralDetails({
    data, patient_id
}) {
    const [loading, setLoading] = useState(true)


    return (

        <Grid container spacing={4}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={8}>
                <Card>
                    <Box sx={{ p: 2, borderBottom: '1px solid #ccc' }}>
                        <Typography variant="h6" color="text.primary">New Patient Referral</Typography>
                        <Typography variant="body2" color="text.secondary">
                            A new referral was recieved form <a href="https://romac.org.au" target="_blank">www.romac.org.au</a> {data?.created_at_dt}
                        </Typography>
                    </Box>
                    <CardContent>
                        <PatientData data={data} />
                        <Medical patient_id={patient_id} />
                        <Referrer patient_id={patient_id} />
                        <Background patient_id={patient_id} />
                        <Guardians patient_id={patient_id} />

                    </CardContent>

                </Card>
            </Grid>

            <Grid item xs={12} md={2}></Grid>
        </Grid>
    )
}