import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Create an async thunk to fetch districts
export const fetchDistricts = createAsyncThunk('districts/fetchDistricts', async () => {
  try {
    const response = await instance.get('/districts');
    return response.data; // Assuming the API response is an array of districts
  } catch (error) {
    throw error;
  }
});

export const District = createSlice({
  name: 'districts',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    addDistrict: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDistricts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addDistrict } = District.actions;

export default District.reducer;
