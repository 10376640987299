import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import instance from '../../../../auth/utils/useJwt';
import DataRows from './DataRows';
import LoadSkeleton from './Skeleton';


export default function Medical({
    patient_id
}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const get_medical = () => {
        setLoading(true)
        instance.get(`/medical-details?id=${patient_id}`).then(res => {
            setData([
                { title: "Medical diagnosis", value: res.data.medical_prognosis },
                { title: "Treatment received prior to referral", value: res.data.prior_treatment },
                { title: "Contact details on treating doctors", value: res.data.treating_doctors },
            ])
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_medical()
    }, [])


    return (
        <Box>
            {loading ?
                <LoadSkeleton rows={4} />
                :
                <DataRows title={"Medical"} data={data} />
            }
        </Box>
    );
}
