import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import instance from '../../../../../auth/utils/useJwt';

const FileUpload = ({
    appendAttachments, invoiceId
}) => {
  const [files, setFiles] = useState([]);
  const [statuses, setStatuses] = useState({});

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => ({ file, status: 'queued' }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);

    newFiles.forEach(uploadFile);
  };

  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
        alert("Please select a file first.");
        return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
        const response = await instance.post(`/xero/invoices/${invoiceId}/attachments`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log("File uploaded successfully: ", response.data);
        appendAttachments(response.data);
    } catch (error) {
        console.error("Error uploading file: ", error);
    }
};



  const uploadFile = async ({ file }) => {
    setStatuses(prevStatuses => ({ ...prevStatuses, [file.name]: 'uploading' }));
    try {
      await handleUpload(file);
      setStatuses(prevStatuses => ({ ...prevStatuses, [file.name]: 'success' }));
    } catch (error) {
      setStatuses(prevStatuses => ({ ...prevStatuses, [file.name]: 'error' }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

  const handleRemoveFile = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(fileObj => fileObj.file.name !== fileName));
    setStatuses(prevStatuses => {
      const { [fileName]: _, ...remainingStatuses } = prevStatuses;
      return remainingStatuses;
    });
  };

  return (
    <Box>
      <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <Typography variant="body1">Drag & drop a file here, or click to select a file</Typography>
        <CloudUploadIcon fontSize="large" />
      </Box>
      <List>
        {files.map(({ file }) => (
          <ListItem key={file.name} secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(file.name)}>
              <DeleteIcon />
            </IconButton>
          }>
            <ListItemText primary={file.name} />
            {statuses[file.name] === 'uploading' && <CircularProgress size={20} />}
            {statuses[file.name] === 'success' && <Typography color="success.main">Success</Typography>}
            {statuses[file.name] === 'error' && <Typography color="error.main">Error</Typography>}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FileUpload;
