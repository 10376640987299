import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReferralPatients } from '../../../redux/referral_patients';
import { Divider } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function DocsSidebarMenu(props) {

    const { allData } = useSelector(state => state.referral_patients)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const location = useLocation()
    const [expanded, setExpanded] = useState('/docx/xero')
    const sidebar_menu = [
        {
            name: 'XERO Integration', slug: '/docx/xero-integration', expanded: true, type: 'main',
            sub_menu: [
                { name: "How it Works", slug: 'how-it-works' },
                { name: "Users and Roles", slug: "users-and-roles" },
                { name: "Expense Invoices", slug: "expense-invoices" },
                { name: "Notifications", slug: "notifications" },
                { name: "Granting Access", slug: "granting-access" }
            ]
        }
    ]

    const onMenuItemClick = (item) => {
        if (item.sub_menu) {
            if (expanded === item.slug) {
                setExpanded(null)
            } else {
                setExpanded(item.slug)
            }
        }
    }

    useEffect(() => {
        if (allData.length === 0) {
            dispatch(fetchReferralPatients())
        }
    }, [])


    const isCurrentNavLink = (item) => {
        const { pathname } = location;
        if (item.sub_menu) {
            if (item.slug === pathname) {
                return true;
            }
            return item.sub_menu.some(subItem => subItem.slug === pathname);
        }
        return item.slug === pathname;
    }

    // If page is "/docx", hide the sidebar


    return (
        <Box sx={{ pt: 1.8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', px: 3, pb: location.pathname === "/docx" ? 1.4 : 1.8, mb: 0.5, borderBottom: "1px solid #ccc" }}>
                <Button size='small' variant='outlined'
                    component={Link} to='/'
                    startIcon={<ArrowBackIcon />} sx={{ width: '100%' }}>
                    Back to Main App
                </Button>
            </Box>
            
            <Box sx={{ px: 2, py: 1 }}>
                <input type='text' placeholder='Search Docs' style={{ width: '100%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc',
                    backgroundColor: "rgb(232, 234, 237)", outline: 'none',
                    boxSizing: 'border-box'
            }} />
            </Box>

            <Divider sx={{ mb: 1 }} />


            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link}
                    to={"/docx"}
                    sx={{
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        py: 0,
                        fontSize: '11px',

                    }}
                >
                    <ListItemText
                        primaryTypographyProps={{ fontSize: '14px' }}
                        primary={"Documentation Home"} sx={{ opacity: open ? 1 : 0, textTransform: 'capitalize' }} />


                </ListItemButton>
            </ListItem>
            <List>



                <Divider sx={{ mb: 1 }} />
                {sidebar_menu.map((sb_item, index) => (
                    <span key={`item-${index}`}>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                // component={sb_item.sub_menu ? Button : Link}
                                component={Link}
                                to={sb_item.slug}
                                onClick={() => onMenuItemClick(sb_item)}
                                sx={{
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    py: 0,
                                    fontSize: '11px',
                                    //backgroundColor: isCurrentNavLink(sb_item) ? 'rgba(0, 127, 255, 1)' : 'none',
                                    //color: isCurrentNavLink(sb_item) ? '#fff' : 'none',
                                    // borderRadius: '7px',
                                    '&:hover': {
                                        backgroundColor: isCurrentNavLink(sb_item) ? 'rgba(0, 127, 255, 0.8)' : 'rgba(0, 127, 255, 0.2)',
                                        color: isCurrentNavLink(sb_item) ? '#fff' : 'none',
                                    }
                                }}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ fontSize: '14px' }}
                                    primary={sb_item.name} sx={{ opacity: open ? 1 : 0, textTransform: 'capitalize' }} />

                                {/* Show keyboard down or up icon based on expanded */}
                                {sb_item.sub_menu && (expanded === sb_item.slug ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />)}
                            </ListItemButton>
                        </ListItem>
                        {sb_item.sub_menu && expanded === sb_item.slug && (
                            sb_item.sub_menu.map((sub_item, subIndex) => (
                                <ListItem key={`sub-item-${subIndex}`} disablePadding sx={{ display: 'block', pr: 3.3 }}>
                                    <ListItemButton
                                        component={Link}
                                        to={`${sb_item.slug}/${sub_item.slug}`}
                                        sx={{
                                            justifyContent: 'initial',
                                            fontSize: '10px',
                                            py: 0,
                                            pl: 4,
                                            // borderRadius: '7px',
                                            // Only apply border radius to top and bottom right corners
                                            borderTopRightRadius: '7px',
                                            borderBottomRightRadius: '7px',
                                            color: isCurrentNavLink(sub_item) ? '#06f' : '#000',
                                            backgroundColor: isCurrentNavLink(sub_item) ? '#E8F0FE' : 'none',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 127, 255, 0.3)',
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: '13px' }}
                                            primary={sub_item.name}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        )}

                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </span>
                ))}
            </List>
        </Box>
    )

}