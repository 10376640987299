import { Button, Card, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function Notifications() {
    const rows = [
        {type: 'New for you', email: true, browser: true, sms: false},
        {type: 'Account activity', email: false, browser: false, sms: false},
        {type: 'A new browser used to sign in', email: true, browser: true, sms: true},
        {type: 'A new device is linked', email: false, browser: true, sms: false},
    ]

    return (
        <Box sx={{ pt: 1 }}>
            <Box>
                <Typography>Notifications</Typography>
                <Typography sx={{
                    fontSize: '13px',
                    py: 1.4,

                }}>
                    You will receive notification for the below selected items.
                </Typography>

            </Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
                        <TableRow>
                            <TableCell sx={{ py: 1.5 }}>Type</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Browser</TableCell>
                            <TableCell>SMS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((item, index) => (
                                <TableRow key={ item.type }>
                                    <TableCell sx={{ fontSize: '15px', fontWeight: 500 }}>{item.type}</TableCell>
                                    <TableCell>
                                        <Checkbox checked={item.email} />    
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={item.browser} />    
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={item.sms} />    
                                    </TableCell>
                                </TableRow>
                            ))
                        }


                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}