import { Box, Button, Stack, styled } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ExportInvoices from "../../../../pam/Invoices/components/ExportInvoices";
import { useSelector } from "react-redux";

const MetaContainer = styled(Box)({
    padding: "10px 7px", color: "#999",
    display: 'flex', gap: 1,
    textAlign: 'center', alignItems: 'center',
    borderRight: '1px solid #eee'
});

const MetaTitle = styled(Box)({
    fontSize: '14px',
    marginRight: '5px'
});

const MetaValue = styled(Box)({
    fontSize: '15px'
});

const tabOptions = [
    { label: "All" },
    { label: "Draft" },
    { label: "Awaiting Payment" },
    { label: "Paid" },
    { label: "Deleted" }
]




export default function MetaHeader({
    invoice
}) {
    const { data } = useSelector(state => state.patient_case)
    const { original_estimate } = data
    
    // sum of all medical and other expenses

    const toFloat = (value) => {
        return parseFloat(value) || 0
    }

    const total = original_estimate?.reduce((acc, curr) => {
        return acc + parseFloat(curr.medical) + parseFloat(curr.other)
    }, 0)
    
    const { patient_id, case_id } = useParams()
    return (
        <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" sx={{
                pl: 0.9
            }}>
                {/* Total Unpaid */}
                <MetaContainer>
                    <MetaTitle>Case Estimate</MetaTitle>
                    <MetaValue>${total?.toFixed(2) || "0.00"}
                    </MetaValue>
                </MetaContainer>
                <MetaContainer>
                    <MetaTitle>Total Unpaid</MetaTitle>
                    <MetaValue>${invoice.reduce((a, b) => a + (b['AmountDue'] || 0), 0).toFixed(2)}</MetaValue>
                </MetaContainer>
                {/* Total Paid */}
                <MetaContainer>
                    <MetaTitle>Total Paid</MetaTitle>
                    <MetaValue>${invoice.reduce((a, b) => a + (b['AmountPaid'] || 0), 0).toFixed(2)}</MetaValue>
                </MetaContainer>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} gap={2} sx={{ pr: 2 }}>
                <ExportInvoices startDate={invoice.length > 0 ? invoice[invoice.length - 1].Date : null}
                endDate={invoice.length > 0 ? invoice[0].Date : null}
                options={tabOptions.map((option) => option.label)}
                variant="default"
                />
                <Button
                    startIcon={<AddIcon />}
                    component={Link} to={`/patients/${patient_id}/case/${case_id}/invoice/new`} size="small">
                    Create Invoice
                </Button>
            </Stack>
        </Stack>
    )
}