import { Button, Card, Checkbox } from "@mui/material";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SelectAutocomplete from "../PatientCases/form_comps/SelectAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchRegions } from "../../../redux/region";
import { fetchMedicalClassifications } from "../../../redux/medical/classification";
import { fetchCountries } from "../../../redux/country";
import { fetchHospitals } from "../../../redux/medical/hospital";
import { fetchDistricts } from "../../../redux/districts";
import { fetchClinicians } from "../../../redux/medical/clinicians";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function FiltersForm(props) {
    const { filters, setFilters, loading } = props
    const dispatch = useDispatch()

    const STATUS_OPTIONS = [
        { label: "", value: "" },
        { label: "ACCEPTED", value: "ACCEPTED" },
        { label: "ASSISTED", value: "ASSISTED" },
        { label: "BY_OTHERS", value: "BY_OTHERS" },
        { label: "COMPLETED", value: "COMPLETED" },
        { label: "CURRENT", value: "CURRENT" },
        { label: "DECEASED", value: "DECEASED" },
        { label: "FACILITATED", value: "FACILITATED" },
        { label: "INVALID_OPS", value: "INVALID_OPS" },
        { label: "MISSING", value: "MISSING" },
        { label: "MONITOR", value: "MONITOR" },
        { label: "PENDING", value: "PENDING" },
        { label: "REFERRAL", value: "REFERRAL" },
        { label: "REFERRED", value: "REFERRED" },
        { label: "REJECTED", value: "REJECTED" }
    ]


    const onChangeInput = (input_name, input_value) => {
        setFilters({ ...filters, [input_name]: input_value })
    }
    const regions = useSelector(state => state.regions).data
    const classifications = useSelector(state => state.classifications).data
    const hospitals = useSelector(state => state.hospitals).data

    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
        if (classifications.length === 0) {
            dispatch(fetchMedicalClassifications())
        }
        if (hospitals.length === 0) {
            dispatch(fetchHospitals())
        }
        
    }, [])

    const YearList = () => {
        const startYear = 2004;
        const currentYear = new Date().getFullYear() + 1;
        const years = [];
      
        for (let i = startYear; i < currentYear; i++) {
          years.push({label : `${i} / ${String(i + 1).slice(2)}`, value: `${i} / ${String(i + 1).slice(2)}`});
        }
        return years.reverse()
    }      

    return (
        <Box>
            <Box sx={{ mb: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <CustomInputLabel>Financial Year</CustomInputLabel>
                        <SelectAutocomplete options={
                            YearList()
                        } value={filters.date} onChange={onChangeInput} name="date" />
                    </Grid>
                    
                    <Grid item md={3}>
                        <CustomInputLabel>Status</CustomInputLabel>
                        <SelectAutocomplete options={STATUS_OPTIONS} value={filters.status} onChange={onChangeInput} name="status" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Region</CustomInputLabel>
                        <SelectAutocomplete options={
                            regions.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.region} onChange={onChangeInput} name="region" />
                    </Grid>
                  
                    <Grid item md={3}>
                        <CustomInputLabel>Condition</CustomInputLabel>
                        <SelectAutocomplete options={
                            classifications.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.condition} onChange={onChangeInput} name="condition" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2} columns={12}>
                    
                   
                    <Grid item md={3}>
                        <CustomInputLabel>Hospital</CustomInputLabel>
                        <SelectAutocomplete options={
                            hospitals.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.hospital} onChange={onChangeInput} name="hospital" />
                    </Grid>

                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '10px' }}>
                {/* <Button variant="outlined" disabled={loading} color="secondary">Add Fields</Button> */}
                <Button variant="outlined" disabled={loading} onClick={props.onClear}>Clear</Button>
                <Button variant="contained" disabled={loading} onClick={() => props.fetchData()}>{
                    loading ? 'Loading...' : 'Update'
                }</Button>
            </Box>
        </Box>
    )
}