import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputLabel from './components/CustomInputLabel';
import { useForm, Controller } from 'react-hook-form';
import instance from '../../../../auth/utils/useJwt';
import DeleteDialog from './components/DeleteDialog';
import { fetchHospitals } from '../../../../redux/medical/hospital';
import DataGridTableLists from './components/DataTableLists';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import FormButtons from './components/FormButtons';
import ErrorMessage from './components/ErrorMessage';

export default function Hospitals({
    open, setOpen
}) {
    const { data, loading } = useSelector(state => state.hospitals)
    const [tableData, setTableData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchHospitals())
        }
    }, [])

    const get_classification = (av) => {
        if (av === 0) {
            return "PRIVATE"
        } else if (av === 1) {
            return "CHILDREN"
        } else {
            return null
        }
    }

    useEffect(() => {
        var dt = []
        data.map((item) => {
            dt.push({ id: item.id, name: item.name.split("(")[0], classification: get_classification(item.classification) })
        })
        setTableData(dt)
    }, [data])

    const handleEdit = (row) => {
        setSelectedRow(row)
        setOpen(true)
    }

    const changeOpen = (ot) => {
        setOpen(false)
        setSelectedRow(null)
    }

    const handleDelete = (did) => {
        setDeleteId(did)
    }


    return (
        <Box>
            <DataGridTableLists data={tableData} loading={loading}
                handleEdit={handleEdit} handleDelete={handleDelete}
                cols={[{ field: 'name', headerName: 'Name', flex: 1 }, { field: 'classification', headerName: 'Classification', flex: 1 }]} />

            <DeleteDialog heading="Delete Hospital" id={deleteId} onDelete={() => dispatch(fetchHospitals())} api_url={"/hospitals"} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mx: 2 }}>
                {/* <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>Add</Button> */}
                <PopUp open={open} setOpen={changeOpen} editRow={selectedRow} />
            </Box>
        </Box>
    );
}


function PopUp(props) {
    const { open, setOpen, editRow } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const dispatch = useDispatch()
    const { register, reset, handleSubmit, control,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (editRow) {
            reset(editRow)
        } else {
            reset({ name: "", id: null })
        }
    }, [editRow])


    const onSubmit = (data) => {
        data.classification = data.classification === '' ? null : data.classification
        setApiReq({ loading: true, error: null })
        if (!editRow) {
            instance.post("/hospitals", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchHospitals())
                reset({ name: "", id: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err.response.data ? err.response.data : "Error adding new region" })
            })
        } else {
            instance.patch("/hospitals", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchHospitals())
                reset({ name: "", id: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err.response.data ? err.response.data : "Error updating region" })
            })
        }
    }
    const onError = (err) => {
        console.log(err)
    }


    return (
        <GeneralDialog open={open} handleClose={setOpen} title={editRow ? 'Edit Hospital' : 'Add Hospital'}
            onFormSubmit={handleSubmit(onSubmit, onError)} disabled={apiReq.loading}
            actions={<FormButtons loading={apiReq.loading} setOpen={setOpen} />}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <CustomInputLabel>Hospital</CustomInputLabel>
                        <TextField {...register('name', { required: true })} error={Boolean(errors.name)} size='small' fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Classification</CustomInputLabel>
                        <Controller
                            name="classification"
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    options={[
                                        { label: "PRIVATE", value: "PRIVATE" },
                                        { label: "CHILDREN", value: "CHILDREN" },
                                    ]}
                                    //getOptionLabel={(option) => option.title}
                                    value={value}
                                    onChange={(_, newValue) => onChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" variant="outlined" fullWidth />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
            {<ErrorMessage error={apiReq.error} />}
        </GeneralDialog>
    );
}
