import { Avatar, Box } from "@mui/material"
import { Link } from "react-router-dom"
const get_romac_link = (id) => {
    // id can be in 2 formats R-1234 or R-1234-1
    // If it is R-1234
    if (id.length === 6) {
        return "/patients/" + id.slice(0, 6)
    }

}


export const GRID_COLUMNS = [
    {
        field: "avatar_patient",
        headerName: "",
        cellClassName: 'first-row-cell',
        width: 60,
        renderCell: (params) => {
            return (
                <Avatar 
                    src={params.row.avatar}
                    alt={params.row.first_name?.slice(0, 1).toLocaleUpperCase()} sx={{
                    width: 39, height: 39, mx: 1, fontSize: '12px', borderRadius: '4px'

                }}>
                    {params.row.first_name?.slice(0, 1).toLocaleUpperCase()} 
                    {params.row.last_name?.slice(0, 1).toLocaleUpperCase()}
                </Avatar>
            )
        }
    },
    {
        field: "romac_id",
        headerName: "Referral ID",
        flex: 1,
        // cellClassName: 'romac-patient-id-cell',
        renderCell: (params) => {
            return (
                <Box
                //  sx={{ pl: 0, ml: 0 }}
                 >
                    {/* <StarPatient row={params.row} /> */}
                <Link to={get_romac_link(params.value)} rel="noreferrer">
                    {params.value}
                </Link>
                </Box>
            )
        }
    },
    {
        field: "first_name",
        headerName: "First Name",
        flex: 1
    },
    {
        field: "last_name",
        headerName: "Last Name",
        flex: 1
    },
    {
        field: "dob",
        headerName: "DOB",
        flex: 1
    },
    {
        field: "region",
        headerName: "Region",
        flex: 1,
        visible: false
    },
    {
        field: "age",
        headerName: "Age",
        flex: 1,
    },
    {
        field: "country",
        headerName: "Country",
        flex: 1,
    }
]
