import { Box, Skeleton, Stack, Tab, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

export default function InsuranceSummary({
    data, loading
}) {
    return (
        <Box sx={{
            minHeight: "300px",
            px: 2, py: 2
        }}>
            <Typography variant="h6">Insurance Summary</Typography>
            {
                loading ? 
                
                
                <Box>
                        <Stack direction={"column"} spacing={1} sx={{ p: 1, px: 2 }}>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        </Stack>
                    </Box>
                : 
            
            <Table>
                <TableBody>
                    {
                        [
                            { label: "Period", value: data?.period },
                            { label: "Cases in Aus/NZ during Period", value: `${data?.cases_in_aus_nz} Cases` },
                            { label: "Patients Arrived", value: `${data?.patients_arrived} Patients` },
                            { label: "Patients Departed", value: `${data?.patients_departed} Patients` },
                            { label: "Patients Archived", value: `${data?.archived} Patients` },
                        ].map((item, index) => {
                            return (

                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography variant="body1">
                                            <strong>{item.label}</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {item.value}
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            }
        </Box>
    )
}